import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})

export class popupService {

    url: string = environment.apis.popup;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            
        })
    }

    constructor(private http: HttpClient) { }

    popUpForm(body) {
        return this.http.post(`${this.url}`, body, this.httpOptions).pipe(
            map(response => {
                return response;
            })
        );
    }
}