import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SucursalesComponent } from './commons/sucursales/sucursales.component';
import { HomeHogarComponent } from './hogar/home-hogar/home-hogar.component';
import { TermsComponent } from './hogar/terms/terms.component';
import { HomeMovilComponent } from './movil/home-movil/home-movil.component';
import { TermsMovilComponent } from './movil/terms-movil/terms-movil.component';
import { HomePrePlanComponent } from './pre-plan/home-pre-plan/home-pre-plan.component';
import { TermsPrePlanComponent } from './pre-plan/terms-movil/terms-pre-plan.component';

const routes: Routes = [
  { path: 'hogar/terminos-y-condiciones', component: TermsComponent },
  {
    path: 'hogar', component: HomeHogarComponent,

    children: [
      { path: ':parameter', component: HomeHogarComponent,},
      { path: '', redirectTo: '/hogar/InternetHogar', pathMatch: 'full', },
    ]
  },
  { path: 'movil', component: HomeMovilComponent },
  { path: 'movil/terminos-y-condiciones', component: TermsMovilComponent },
  {
    path: 'movil', component: HomeMovilComponent,
    children: [
      { path: ':parameter', component: HomeMovilComponent },
      { path: '', redirectTo: '/movil', pathMatch: 'full' },
    ]
  },
  { path: 'plan-para-todos', component: HomePrePlanComponent },
  { path: 'plan-para-todos/terminos-y-condiciones', component: TermsPrePlanComponent },
  {
    path: 'plan-para-todos', component: HomePrePlanComponent,
    children: [
      { path: ':parameter', component: HomePrePlanComponent },
      { path: '', redirectTo: '/plan-para-todos', pathMatch: 'full' },
    ]
  },
  {
    path: 'sucursales', component: SucursalesComponent, children: [
      { path: ':category', component: SucursalesComponent }
    ]
  },
  { path: '**', redirectTo: 'hogar/InternetHogar' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
