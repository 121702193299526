import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header-hogar',
  templateUrl: './header-hogar.component.html',
  styleUrls: ['./header-hogar.component.scss']
})
export class HeaderHogarComponent implements OnInit {


  public itemActive: string = 'itemHogar1';


  constructor(
    public router: Router,


  ) { }

  ngOnInit(): void {
  }


  inView(itemHogar:any){
    itemHogar.scrollIntoView({behavior:"smooth", block:"start", inline:"start"})
    this.itemActive = itemHogar.id;
  }

  closeNest(){
    const dropdown = document.getElementById('drop');
    dropdown?.classList.remove('show');
  }

 


}
