<div data-bs-spy="scroll" data-bs-target="#navbar-scrollMovil" data-bs-root-margin="0px 0px -40%"
  data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary rounded-2" tabindex="0">

  <div *ngIf="this.router.url.includes('/PlanesIndividuales')">
    <section
      class="bg-gray p-3 d-flex flex-md-row flex-column align-items-center justify-content-center w-100 text-center gap-md-5 gap-2">
      <h2 class="fw-medium text-black fs-5 mb-0">Contrata paquetes adicionales iguales a tu plan por
        <span class="fw-bold">$5.990c/u</span>
      </h2>
    </section>
    <section>
      <div class="container mt-3 ">
        <div class="row d-flex justify-content-center">
          <div class="flex d-flex flex-column justify-content-center align-items-center mb-5 gap-3">
            <span class="text-black fs-4">¿Cúantos planes quieres contratar?</span>
            <div class="rounded-9  px-5 d-flex align-items-center gap-5 shadow">
              <button (click)="reduceQuantity()" class="border-0 fs-2  bg-transparent colorMovistar">-</button>
              <span class="fs-4 colorMovistar px-5 border-top-0 border-bottom-0 borderblue">{{cuantity}}</span>
              <button (click)="aumentQuantity()" class=" border-0 fs-2 bg-transparent colorMovistar">+</button>
            </div>
          </div>


          <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
            <app-caluga [type]="'movil'" [cuantity]="cuantity" [producto]="product"></app-caluga>
          </div>
        </div>
      </div>
    </section>


    <section class="container bg-light mt-5 py-4">
      <div class="container">
        <h2 class="fw-bold colorMovistar mb-4">Nuestros Planes incluyen</h2>
      </div>
      <div class="row d-flex flex-column flex-md-row">
        <div class="col-12 col-md-4">
          <h4 class="text-black mb-2">Internet</h4>
          <div class="row">
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/Gigas.svg" height="40" width="40">
              <div>
                <span class="fw-bold text-black mb-0">Gigas Libres</span>
              </div>
            </div>
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/roaming.svg" height="40" width="40" fill="#00a9e0;">
              <div>
                <span class="fw-bold text-black mb-0">Roaming por 7 días desde</span>
                <p class="text-black mb-0 fs08">Plan 5G libre full</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>

  <div *ngIf="this.router.url.includes('/Planes2x')">
    <section>
      <div class="container mt-3">
        <div class="row d-flex justify-content-center">
          <div class="d-flex justify-content-between justify-content-lg-center gap-md-4">
            <div class="button-nav text-nowrap px-2 " [routerLink]="['/movil/PlanesIndividuales']">Planes Individuales
            </div>
            <div class="button-nav text-nowrap px-2 " [routerLink]="['/movil/Planes2x']">Pack 2x</div>
          </div>

          <div class="col-12 text-center my-4">
            <h1 class="text-black fw-light"><span class="colorMovistar fw-bold">Los mejores Planes Móviles</span> y
              Ofertas
              Portabilidad</h1>
            <p class="h4 mt-4">

            </p>
          </div>


          <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
            <app-caluga [type]="'movil'" [producto]="product"></app-caluga>
          </div>
        </div>
      </div>
    </section>
    <section class="container">

    </section>
    <section class="container bg-light mt-5 py-4">
      <div class="container">
        <h2 class="fw-bold colorMovistar mb-4">Nuestros Planes incluyen</h2>
      </div>
      <div class="row d-flex flex-column flex-md-row">
        <div class="col-12 col-md-4">
          <h4 class="text-black mb-2">Internet</h4>
          <div class="row">
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/Gigas.svg" height="40" width="40">
              <div>
                <span class="fw-bold text-black mb-0">Gigas Libres</span>
              </div>
            </div>
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/roaming.svg" height="40" width="40" fill="#00a9e0;">
              <div>
                <span class="fw-bold text-black mb-0">Roaming por 7 días desde</span>
                <p class="text-black mb-0 fs08">Plan 5G libre full</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <h4 class="text-black mb-2">Streaming</h4>
          <div class="row">
            <div class="col-12 d-flex gap-4 align-items-center mb-4">
              <img src="../../../assets/img/Iconos Landing/movistar_play.svg" alt="movistar_play" height="40"
                width="40">
              <div>
                <span class="fw-bold text-black mb-0">Movistar TV Free</span>
                <p class="text-black mb-0 fs08">Entretención para nunca aburrirse.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>

</div>