import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../../commons/service/product.service';
@Component({
  selector: 'app-banners-hogar',
  templateUrl: './banners-hogar.component.html',
  styleUrls: ['./banners-hogar.component.scss']
})
export class BannersHogarComponent implements OnInit {

  constructor(
    private hogarService: ProductService,
    private router: Router
  ) { }

  sliders: string[] = [];
  device: string;


  ngOnInit(): void {
    this.getDimensions();
  }

  ngAfterViewInit(){
    this.getDeals();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.getDimensions();
    this.getDeals()
  }
  
  getDimensions() {
    window.innerWidth <= 600
      ? (this.device = 'Mobile')
      : (this.device = 'Desktop');
  }

  getDeals() {
    if (this.device) {
      this.hogarService.getDeals('TODOS', '1', this.device, 'TODOS', 'Hogar').subscribe((results: any) => {
        this.sliders = results;
      });
    }
  }

}
