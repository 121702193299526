<app-header-movil></app-header-movil>
<app-banners-movil></app-banners-movil>
<app-products-movil></app-products-movil>
<app-footer-movil></app-footer-movil>





<!-- <div class="modal fade modalHogar " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered justify-content-center">
    <div class="modal-content bg-image imagebackground">
      <div class="image-container">
        <button type="button" class="btn-close btn-close-white position-absolute btn-x" (click)="onCloseModal()"
          data-mdb-dismiss="modal" aria-label="Close"></button>
        <img class="img-modal"
          src="https://s3.sa-east-1.amazonaws.com/imagenes.simple.cl/Banners_Descuentos/Abril_2023/M%C3%B3vil.png">
      </div>
      <div class="container text-center mt-1 ">
        <p class="fw-bold fs-6" style="color:#00b2eb">¡No te pierdas los descuentos que tenemos para ti!</p>
        <form [formGroup]="popUpForm">
          <div class="row">
            <div class="col">
              <input type="email" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="Nombre"
                id="client_name" name="client_name" formControlName="client_name">
            </div>
            <div class="col">
              <input type="email" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="Apellido"
                id="client_lastname" name="client_lastname" formControlName="client_lastname">
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input type="rut" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="Rut"
                id="client_identityNumber" name="client_identityNumber" formControlName="client_identityNumber">
            </div>
            <div class="col">
              <input type="contacto" id="defaultLoginFormEmail" class="form-control mb-4"
                placeholder="N°Contacto (9 digitos)" id="client_phoneNumber" name="client_phoneNumber"
                formControlName="client_phoneNumber">
            </div>
          </div>
          <input type="correo" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="Correo Electronico"
            id="client_email" name="client_email" formControlName="client_email">
          <input type="text" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="Region"
            id="client_region" name="client_region" formControlName="client_region">
          <div class="row">
            <div class="col">
              <input type="text" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="Comuna"
                id="client_commune" name="client_commune" formControlName="client_commune">
            </div>
            <div class="col">
              <input type="text" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="Dirección"
                id="client_address" name="client_address" formControlName="client_address">
            </div>
            <div class="col">
              <input type="text" id="defaultLoginFormEmail" class="form-control mb-4" placeholder=" Numero de Dirección"
                id="client_addressNumber" name="cclient_addressNumber" formControlName="client_addressNumber">
            </div>
          </div>
        </form>
      </div>
      <p class="text-danger text-center" *ngIf="error">Verifique los campos</p>
      <div class="modal-footer border-0 ">
        <button id="revFactibilidad" type="button" class="btn btn-primary buttongreen" data-bs-dismiss="modal"
          (click)="send()">Revisar
          Factibilidad</button>
      </div>
      <p class="text-success text-center" *ngIf="success">Tus datos se han ingresado correctamente</p>
    </div>
  </div>
</div>


<div class="backdrop"></div> -->