import { AbstractControl } from '@angular/forms';

export function RutValidator(control: AbstractControl) {
    if (typeof control.value !== 'string') {
        return false;
    }

    const rut: string = control.value.replace(/[^0-9kK]+/g, '').toUpperCase();
    let rutDigits: number = parseInt(rut.slice(0, -1), 10);
    let m = 0;
    let s = 1;

    while (rutDigits > 0) {
        s = (s + rutDigits % 10 * (9 - m++ % 6)) % 11;
        rutDigits = Math.floor(rutDigits / 10);
    }
    const checkDigit: string = (s > 0) ? String((s - 1)) : 'K';

    if (checkDigit !== rut.slice(-1)) {
        return { rut: false };
    }
    return null;
}
