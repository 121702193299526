import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Caluga } from 'src/app/commons/interfaces/caluga';
import { ProductService } from 'src/app/commons/service/product.service';

@Component({
  selector: 'app-products-hogar',
  templateUrl: './products-hogar.component.html',
  styleUrls: ['./products-hogar.component.scss']
})
export class ProductsHogarComponent implements OnInit, OnDestroy {

  productos: Caluga[] = [];
  category: string;
  subscriptions: Subscription[] = [];

  constructor(
    private productService: ProductService,
    public router: Router,
    private route: ActivatedRoute,
  ) {





  }

  ngOnInit(): void {
    this.addParamsToUrl();
    window.scroll(0, 0)
    this.route.url.subscribe((params: any) => {
    })
    this.route.firstChild.params.subscribe((params: any) => {
      this.category = params["parameter"];
      switch (params["parameter"]) {

        case "InternetHogar":
          this.getProducts(4);
          break;

        case "PacksDigitalLight":
          this.getProducts(5);
          break;
        case "PacksDigitalFull":
          this.getProducts(14);
          break;

        case "PacksDuosCombo":
          this.getProducts(16);
          break;
        case "PacksDuos":
          this.getProducts(20);
          break;
        case "PacksDuosStar":
          this.getProducts(20);
          break;
        case "PacksDuosTlf":
          this.getProducts(13);
          break;

        case "PacksTriosCombo":
          this.getProducts(21);
          break;
        case "PacksTriosStar":
          this.getProducts(17);
          break;

        default:
          this.getProducts(4);
          break;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  addParamsToUrl() {
    window.scroll(0, 0)
    this.route.url.subscribe((params: any) => {
    })
    this.route.firstChild.params.subscribe((params: any) => {
      this.category = params["parameter"];
      switch (params["parameter"]) {

        case "InternetHogar":
          this.router.navigate(['/hogar/InternetHogar'], );
          break;
        case "PacksDuosCombo":
          this.router.navigate(['/hogar/PacksDuosCombo'], );
          break;
        case "PacksDuosStar":
          this.router.navigate(['/hogar/PacksDuosStar'], );
          break;
        case "PacksTriosCombo":
          this.router.navigate(['/hogar/PacksTriosCombo'], );
          break;
        case "PacksTriosStar":
          this.router.navigate(['/hogar/PacksTriosStar'], );
          break;
      }
    });
  }

  getProducts(category_id) {
    this.productos = [];
    const sub = this.productService.getProducts(category_id, '1').subscribe((planes: Caluga[]) => {
      if (!planes || planes.length <= 0) {
        return;
      }
      planes.forEach((producto: Caluga) => {
        if (producto) {
          this.productos.push(producto);
        }
      });
    });
    this.subscriptions.push(sub);

  }

}
