import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import {ReactiveFormsModule} from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderMovilComponent } from './movil/header-movil/header-movil.component';
import { BannersMovilComponent } from './movil/banners-movil/banners-movil.component';
import { ProductsMovilComponent } from './movil/products-movil/products-movil.component';
import { FooterMovilComponent } from './movil/footer-movil/footer-movil.component';
import { HeaderHogarComponent } from './hogar/header-hogar/header-hogar.component';
import { BannersHogarComponent } from './hogar/banners-hogar/banners-hogar.component';
import { ProductsHogarComponent } from './hogar/products-hogar/products-hogar.component';
import { FooterHogarComponent } from './hogar/footer-hogar/footer-hogar.component';
import { HomeHogarComponent } from './hogar/home-hogar/home-hogar.component';
import { HomeMovilComponent } from './movil/home-movil/home-movil.component';
import { CalugaComponent } from './commons/caluga/caluga.component';
import { CurrencyFormatPipe } from './commons/pipes/currency-format.pipe';
import { TermsComponent } from './hogar/terms/terms.component';
import { TermsMovilComponent } from './movil/terms-movil/terms-movil.component';
import { BannerComponent } from './commons/banner/banner.component';
import { SucursalesComponent } from './commons/sucursales/sucursales.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BannersPrePlanComponent } from './pre-plan/banners-pre-plan/banners-pre-plan.component';
import { FooterPrePlanComponent } from './pre-plan/footer-pre-plan/footer-pre-plan.component';
import { HeaderPrePlanComponent } from './pre-plan/header-pre-plan/header-pre-plan.component';
import { HomePrePlanComponent } from './pre-plan/home-pre-plan/home-pre-plan.component';
import { ProductsPrePlanComponent } from './pre-plan/products-pre-plan/products-pre-plan.component';
import { TermsPrePlanComponent } from './pre-plan/terms-movil/terms-pre-plan.component';
import { timmerComponent } from './commons/timmer/timmer.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderMovilComponent,
    BannersMovilComponent,
    ProductsMovilComponent,
    FooterMovilComponent,
    HeaderHogarComponent,
    BannersHogarComponent,
    ProductsHogarComponent,
    FooterHogarComponent,
    HomeHogarComponent,
    HomeMovilComponent,
    BannersPrePlanComponent,
    FooterPrePlanComponent,
    HeaderPrePlanComponent,
    HomePrePlanComponent,
    ProductsPrePlanComponent,
    TermsPrePlanComponent,
    CalugaComponent,
    CurrencyFormatPipe,
    TermsComponent,
    TermsMovilComponent,
    BannerComponent,
    timmerComponent,
    SucursalesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
