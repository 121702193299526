import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(value == null){
      return '';
    }
    const rx = /(\d+)(\d{3})/;
    value = parseInt(String(value),10);
    return String(value).replace(/^\d+/, function(w) {
      while (rx.test(w)) {
        w = w.replace(rx, '$1.$2');
      }
      return '$'+w;
    });
  }

}
