import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Result } from 'src/app/movil/interfaces/result';
import { environment } from 'src/environments/environment';
import { Caluga } from '../interfaces/caluga';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  url: string = environment.apis.distributionProducts;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient
  ) { }

  // deals/list?category=TODOS&activeIndicator=TODOS&platform=TODOS&dealName=TODOS&link=TODOS&section=TODOS
  getProducts(category = 'TODOS', activeIndicator = '1', outsourcing = 'TODOS', productName = 'TODOS') {
    return this.http.get(`${this.url}/products/list?category=${category}&activeIndicator=${activeIndicator}&outsourcing=${outsourcing}&productName=${productName}`, this.httpOptions)
      .pipe(
        map((response: any) => {
          if (response.code === 0)
            return response.data;
          else
            return null;
        })
      )
  }


  getDeals(category = 'TODOS', activeIndicator = '1', platform = 'TODOS', link = 'TODOS', section = 'TODOS', dealName = 'TODOS') {
    return this.http.get(`${this.url}/deals/list?category=${category}&activeIndicator=${activeIndicator}&platform=${platform}&dealName=${dealName}&link=${link}&section=${section}`, this.httpOptions)
      .pipe(
        map((response: Result) => {
          return response.data;
        })
      )
  }
  getSucursales(category = 'TODOS', activeIndicator = '1', servicio = 'TODOS', latitud = 'TODOS', longitud = 'TODOS') {
    return this.http.get(`${this.url}/sucursales/list?category=${category}&activeIndicator=${activeIndicator}&servicio=${servicio}&latitud=${latitud}&longitud=${longitud}`, this.httpOptions)
      .pipe(
        map((response: Result) => {
          return response.data;
        })
      )
  }
}