<app-header-pre-plan></app-header-pre-plan>
<app-banners-pre-plan></app-banners-pre-plan>
<app-products-pre-plan></app-products-pre-plan>
<app-footer-pre-plan></app-footer-pre-plan>




<!-- <div class="modal fade modalHogar " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered justify-content-center">
    <div class="modal-content bg-image imagebackground">
      <div class="image-container">
        <button type="button" class="btn-close btn-close-white position-absolute btn-x" (click)="onCloseModal()"
          data-mdb-dismiss="modal" aria-label="Close"></button>
        <a
          href="https://catalogo.movistar.cl/planes/solicitud/detalle/index/id/44178?utm_source=google&utm_medium=cpc&utm_campaign=cl_movil_col-Afiliados_simple_5G_full&utm_term=simple_2023&utm_content=planes_5g">
          <img class="img-modal"
            src="https://s3.sa-east-1.amazonaws.com/imagenes.simple.cl/DescuentosMovistar/FD7955F6-D80C-4130-8BDC-D87512B64275+(1).png">
        </a>
      </div>
    </div>
  </div>
</div>


<div class="backdrop"></div> -->