<div [ngClass]="{'d-none': hide == true }" class="count-down-timer">
    <div class=" d-flex flex-column flex-md-row gap-md-5 gap-2 justify-content-center align-items-center p-2 w-100">
        <div class="text-center">
            <p class="fs-5 m-0">¡Apúrate antes de que los descuentos se agoten! 😱</p>
        </div>
        <div class="d-flex colorMagenta fw-bold align-items-center justify-content-center gap-3">
            <div class="d-flex justify-content-center align-items-center flex-column">
                <p class="mb-0 p-0">Horas</p>
                <p class="fs-3 p-0 m-0" #hours></p>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column">
                <p class="mb-0 p-0">Minutos</p>
                <p class="fs-3 p-0 m-0" #minutes></p>
            </div>
            <div class="d-flex justify-content-center align-items-center flex-column">
                <p class="mb-0 p-0">Segundos</p>
                <p class="fs-3 p-0 m-0" #seconds></p>
            </div>
        </div>

    </div>
</div>