<div data-bs-spy="scroll" data-bs-target="#navbar-scrollMovil" data-bs-root-margin="0px 0px -40%"
  data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary p-3 rounded-2" tabindex="0">


  <div *ngIf="this.router.url.includes('/PlanesParaTodos')">
    <section>
      <div class="container mt-3">
        <div class="row d-flex justify-content-center">

          <div class="col-12 text-center mb-4">
            <h1 class="fw-bold colorMovistar">Planes Para Todos</h1>
            <p class="h5">
              Planes sin<span class="colorMagenta"> contratos </span>ni<span class="colorMagenta"> requisitos </span>
            </p>
          </div>
          <!-- <div class="col-12">
            <p>
              Descripción: <br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde aspernatur est assumenda
            </p>
          </div> -->
          <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
            <app-caluga [type]="'movilPlanParaTodos'" [producto]="product"></app-caluga>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light mt-5 pt-4 planes-includes">
      <div class="container card border rounded-7 p-4">
        <div class="row d-flex justify-content-evenly">
          <div class="col-12 text-center mb-3">
            <h2 class="fw-bold">¿Como funciona?</h2>
          </div>
          <div class="col-12 col-md-3 pasos">
            <h5 class="h3 fw-bold m-0 p-0 colorMagenta">Activación</h5>
            <p class="fs-7 m-0">Una Vez tengas tu nuevo Chip solo debees insertarlo en tu equipo al prenderlo recibirás
              un SMS de bienvenida a tu nuevo Plan para Todos.</p>
          </div>
          <div class="col-12 col-md-3 pasos">
            <h5 class="h3 fw-bold m-0 p-0 colorMagenta">Renovación</h5>
            <p class="fs-7 m-0">Una Vez que está activo el plan lo renovaremos automáticamente cada 30 días, siempre que
              cuentes con el saldo suficiente del valor de tu plan.</p>
          </div>
          <div class="col-12 col-md-3 pasos">
            <h5 class="h3 fw-bold m-0 p-0 colorMagenta">Saldo suficiente</h5>
            <p class="fs-7 m-0">Tranquilo, si no tienes el saldo correspondiente a tu plan al momento de la renovación,
              no te preocupes tu plan se reactivará automáticamente una vez que cuentes con el saldo.</p>
          </div>
        </div>
      </div>
    </section>

  </div>

</div>