<!-- Carousel wrapper -->
<div id="carouselBannersHogar" class="carousel slide carousel-fade" data-mdb-ride="carousel">
  <!-- Rounded Indicators -->
  <div class="carousel-indicators">
    <button type="button" *ngFor="let slider of sliders" [ngClass]="{active: slider.orderDe === '1'}"
      data-mdb-target="#carouselBannersHogar" [attr.data-mdb-slide-to]="slider.orderDe - 1"
      aria-label="Slide {{slider.orderDe}}"></button>
  </div>



  <!-- Inner -->
  <div class="carousel-inner">
    <!-- Single item -->
    <div class="carousel-item" *ngFor="let slider of sliders" [ngClass]="{active: slider.orderDe === '1'}">
      <a href="{{slider.link}}" target="_blank">
        <img src="{{slider.imageWeb}}" [ngClass]="device === 'desktop' ? 'imgBanner d-none d-md-inline-block' : ''"
          class="d-block w-100 img-banner img-fluid" />
      </a>
    </div>

    <button class="carousel-control-prev" type="button" data-mdb-target="#carouselBannersHogar" data-mdb-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Anterior</span>
    </button>
    <button class="carousel-control-next" type="button" data-mdb-target="#carouselBannersHogar" data-mdb-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Siguiente</span>
    </button>

  </div>
  <!-- Carousel wrapper -->