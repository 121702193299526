import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'timmer-banners-hogar',
    templateUrl: './timmer.component.html',
    styleUrls: ['./timmer.component.scss']
})
export class timmerComponent implements OnInit {
    today = new Date();
    tomorrow = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1, 5, 59, 59);
    endTimer = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1, 5, 59, 59).getTime();
    difference = this.endTimer - this.today.getTime();
    mstoHours = Math.round(this.difference / 1000 / 60 / 60);
    hide : boolean
    keyStorage = localStorage.getItem('timer')


    @ViewChild('hours', { static: true }) hours: ElementRef;
    @ViewChild('minutes', { static: true }) minutes: ElementRef;
    @ViewChild('seconds', { static: true }) seconds: ElementRef;


    getTimer(hour: number) {
        const keyStorage = localStorage.getItem('timer') 
        if(!keyStorage){
            localStorage.setItem('timer', 'true');
        }else{
            console.log('ya existe')
            if(this.today > this.tomorrow){
                this.hide = true
            }

        }
        let distance = (hour * 60 * 60 * 1000) - 1000;
        return timer(0, 1000).pipe(
            takeUntil(timer(distance)),
            map(() => {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                distance -= 1000;
                this.hours.nativeElement.innerText = hours;
                this.minutes.nativeElement.innerText = minutes;
                this.seconds.nativeElement.innerText = seconds;
                return `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${minutes ? minutes + 'm' : ''} ${seconds ? seconds + 's' : ''}`;
            })
        );
    };

    ngOnInit(): void {
        this.getTimer(this.mstoHours).subscribe();
        console.log(this.hide)
        console.log(this.today)
        console.log(this.tomorrow)
        console.log(this.keyStorage)
    }
}
