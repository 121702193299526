import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService } from '../../commons/service/product.service';

@Component({
  selector: 'app-banners-pre-plan',
  templateUrl: './banners-pre-plan.component.html',
  styleUrls: ['./banners-pre-plan.component.scss']
})
export class BannersPrePlanComponent implements OnInit {
  
  category: string;

  constructor(
    private movilService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    
  ) { }

  sliders: string[] = [];
  device: string;

  ngOnInit(): void {
    this.getDimensions();
    window.scroll(0, 0)
    this.route.url.subscribe((params: any) => {
    })
    this.route.firstChild.params.subscribe((params: any) => {
      this.category = params["parameter"];
      switch (params["parameter"]) {
        case "PlanesParaTodos":
          this.getDeals('PlanesParaTodos');
          break;
        default:
          this.getDeals('Movil');
          break;
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.getDimensions();
    this.getDeals(this.category)
  }
  
  getDimensions() {
    window.innerWidth <= 600
      ? (this.device = 'Mobile')
      : (this.device = 'Desktop');
  }

  getDeals(category) {
    if (this.device) {
      this.movilService.getDeals('TODOS', '1', this.device, 'TODOS', category).subscribe((results: any) => {
        this.sliders = results;
      });
    }
  }

}
