<div data-bs-spy="scroll" data-bs-target="#navbar-scrollMovil" data-bs-root-margin="0px 0px -40%"
    data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary  rounded-2" tabindex="0">

    <!-- Planes Hogar  -->
    <div *ngIf="this.router.url.includes('/InternetHogar')">
        <section>
            <div class="container-home container-sm mt-3 mx-auto ">
                <div class="row d-flex  justify-content-center">
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>

        <section class="container  mt-5 py-4 mx-auto">
            <div class=" container ">
                <h2 class="fw-bold colorMovistar mb-4 text-center fs-42">Beneficios de contratar Internet hogar Movistar
                </h2>
                <div
                    class="row d-flex flex-column flex-md-row  justify-content-between align-items-center p-2 p-md-0 gap-5 ">
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <div>
                            <h3 class="text-black fs-3 fw-bold">Máxima velocidad</h3>
                            <p class="text-black fs-6 mt-2  "> Con la fibra óptica de Movistar, los usuarios pueden
                                disfrutar
                                de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                                descargas rápidas y una experiencia de streaming sin interrupciones.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Conexión más segura y estable</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica garantiza una conexión a Internet más
                                estable y
                                segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                                usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o
                                disfrutar
                                de juegos en línea sin sufrir cortes o retrasos.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4  ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Más dispositivos conectados</h3>
                            <p class="text-black fs-6 mt-2 ">Los planes de Internet hogar de Movistar permiten la
                                conexión de
                                hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                                conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                                dispositivos conectados simultáneamente.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-internet-wifi.svg" height="40"
                            width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Velocidad simétrica</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica de Movistar ofrece una velocidad
                                simétrica,
                                es
                                decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                                conexión a Internet, lo que resulta especialmente útil para actividades que requieren
                                una
                                alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-gray mt-5 py-4">
            <div class="container">
                <h2 class="fw-bold colorMovistar mb-4 text-center text-md-start">Nuestros Planes incluyen</h2>
                <div class="d-flex justify-content-between gap- justify-content-center4 flex-column flex-md-row">
                    <div class="row  containerrr">
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Internet</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img class="" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">App Smart Wifi</span>
                                        <p class="text-black mb-0 fs08">Administra tu red fácilmente</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Instalación sin costo en 48 horas</span>
                                        <p class="text-black mb-0 fs08">Podrás agendar la instalación con un ejecutivo
                                            cuando
                                            más
                                            te acomode.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Television</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/decodificador_azul.svg"
                                        alt="decodificador" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">1 decodificador incluido con Movistar
                                            TV</span>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/iconTV.svg" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">101 canales únicos (89 HD).</span>
                                        <p class="text-black mb-0 fs08">9 abiertos (7 HD)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between flex-column gap-2">
                        <div class="d-flex  gap-4">
                            <img src="../../../assets/img/Iconos Landing/primelogo.png" height="50" width="50">
                            <div>
                                <h5 class="h6 fw-bold m-0 p-0 text-black">El primer mes invita Movistar </h5>
                                <p class="fs-12 text-black">A partir del mes 2 pagas $5.790 al mes.</p>
                            </div>
                        </div>
                        <div class="d-flex  gap-4">
                            <img src="../../../assets/img/Iconos Landing/espnlogo.png" height="30" width="50">
                            <div>
                                <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $9.990 mensual</h5>

                            </div>
                        </div>
                        <div class="d-flex text-center gap-4">
                            <img src="../../../assets/img/Iconos Landing/TNTSportsPremium2024.svg" height="50"
                                width="50">
                            <div>
                                <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $12.490/mes
                                </h5>
                                <p class="fs-12 text-black">Incluye acceso <span>
                                        <img src="../../../assets/img/Iconos Landing/eSTADIO.png" height="20"
                                            width="60">
                                    </span></p>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </section>
    </div>

    <!-- Planes Digitales  -->
    <div *ngIf="this.router.url.includes('/PacksDigitalLight')">
        <section>
            <div class="container mt-3">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 text-center mb-4">
                        <h1 class="colorMovistar">Internet Hogar + Netflix</h1>
                        <p class="h5">
                            Todos tus contenidos donde quieras y cuando quieras
                        </p>
                    </div>
                    <!-- <div class="col-12">
                        <p>
                            &nbsp;: <br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde aspernatur
                            est assumenda
                        </p>
                    </div> -->
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>



        <section class="container-home container-sm  mt-5 py-4 mx-auto">
            <div class=" container-home container-sm ">
                <h2 class="fw-bold colorMovistar mb-4 text-center fs-1">Beneficios de contratar Internet hogar Movistar
                </h2>
                <div
                    class="row d-flex flex-column flex-md-row  justify-content-center align-items-center p-2 p-md-0 gap-5 ">
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <div>
                            <h3 class="text-black fs-3 fw-bold">Máxima velocidad</h3>
                            <p class="text-black fs-6 mt-2 w-75 "> Con la fibra óptica de Movistar, los usuarios pueden
                                disfrutar
                                de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                                descargas rápidas y una experiencia de streaming sin interrupciones.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Conexión más segura y estable</h3>
                            <p class="text-black fs-6 mt-2 w-75"> La fibra óptica garantiza una conexión a Internet más
                                estable y
                                segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                                usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o
                                disfrutar
                                de juegos en línea sin sufrir cortes o retrasos.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4  ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Más dispositivos conectados</h3>
                            <p class="text-black fs-6 mt-2 w-75">Los planes de Internet hogar de Movistar permiten la
                                conexión de
                                hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                                conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                                dispositivos conectados simultáneamente.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-internet-wifi.svg" height="40"
                            width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Velocidad simétrica</h3>
                            <p class="text-black fs-6 mt-2 w-75"> La fibra óptica de Movistar ofrece una velocidad
                                simétrica,
                                es
                                decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                                conexión a Internet, lo que resulta especialmente útil para actividades que requieren
                                una
                                alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-light mt-5 pt-4 planes-includes">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 text-center mb-3">
                        <h5 class="fw-light">Todos nuestros Packs Digitales incluyen</h5>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <img src="../../../assets/img/Iconos Landing/Netflix.svg" height="40" width="40"
                            class="rounded-7">
                        <!-- <h5 class="h6 fw-bold m-0 p-0">Netflix</h5> -->
                        <p class="colorMovistar fs-12">No te pierdas las mejores series y estrenos originales</p>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <img src="../../../assets/img/Iconos Landing/movistar_play.svg" height="40" width="40">
                        <h5 class="h6 fw-bold m-0 p-0"> Multipantalla Free</h5>
                        <p class="colorMovistar fs-12">TV online con 8 canales en vivo</p>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div *ngIf="this.router.url.includes('/PacksDigitalFull')">
        <section>
            <div class="container mt-3">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 text-center mb-4">
                        <h1 class="fw-bold">Internet Hogar + Netflix</h1>
                        <p class="h5">
                            Todos tus contenidos donde quieras y cuando quieras
                        </p>
                    </div>
                    <!-- <div class="col-12">
                        <p>
                            &nbsp;: <br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde aspernatur
                            est assumenda
                        </p>
                    </div> -->
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>



        <section class="container mt-5 py-4">
            <div class="contianer">
                <h2 class="fw-bold colorMovistar mb-4 text-center">Beneficios de contratar Internet hogar Movistar</h2>
                <div class="row d-flex flex-column flex-md-row  p-2 p-md-0 ">
                    <div class="col-12 col-md-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <h3 class="text-black fs-3">Máxima velocidad:</h3>
                        <p class="text-black fs-6 mt-2"> Con la fibra óptica de Movistar, los usuarios pueden disfrutar
                            de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                            descargas rápidas y una experiencia de streaming sin interrupciones.
                        </p>
                    </div>
                    <div class="col-12 col-md-4">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <h3 class="text-black fs-3">Conexión más segura y estable:</h3>
                        <p class="text-black fs-6 mt-2"> La fibra óptica garantiza una conexión a Internet más estable y
                            segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                            usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o disfrutar
                            de juegos en línea sin sufrir cortes o retrasos.
                        </p>
                    </div>
                    <div class="col-12 col-md-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <h3 class="text-black fs-3">Más dispositivos conectados:</h3>
                        <p class="text-black fs-6 mt-2">Los planes de Internet hogar de Movistar permiten la conexión de
                            hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                            conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                            dispositivos conectados simultáneamente.
                        </p>
                    </div>
                    <div class="col-12 col-md-4">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <h3 class="text-black fs-3">Velocidad simétrica:</h3>
                        <p class="text-black fs-6 mt-2"> La fibra óptica de Movistar ofrece una velocidad simétrica, es
                            decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                            conexión a Internet, lo que resulta especialmente útil para actividades que requieren una
                            alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                        </p>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-light mt-5 pt-4">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 text-center mb-3">
                        <h5 class="fw-light">Todos nuestros Packs Digitales incluyen</h5>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <img src="../../../assets/img/Iconos Landing/Netflix.svg" height="40" width="40"
                            class="rounded-7">
                        <!-- <h5 class="h6 fw-bold m-0 p-0">Netflix</h5> -->
                        <p class="colorMovistar fs-12">No te pierdas las mejores series y estrenos originales</p>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <img src="../../../assets/img/Iconos Landing/movistar_play.svg" height="40" width="40">
                        <h5 class="h6 fw-bold m-0 p-0"> Multipantalla Free</h5>
                        <p class="colorMovistar fs-12">TV online con 8 canales en vivo</p>
                    </div>
                </div>
            </div>
        </section>

    </div>

    <!-- Planes Duos -->
    <div *ngIf="this.router.url.includes('/PacksDuosCombo')" class="planes-includes">
        <section
            class="bg-gray p-3 d-flex flex-md-row flex-column align-items-center justify-content-center w-100 text-center gap-md-5 gap-2">
            <h2 class="fw-bolder text-black fs-3 mb-0">Contrata la fibra simetrica más rápida de Chile</h2>
            <div class="d-flex flex-row align-items-center justify-content-between gap-4">
                <p class="fs-3 text-black mb-0">Todos los planes incluyen</p>
                <div>
                    <img class="rounded-4" src="../../../assets/img/Iconos Landing/Icon_paramount.svg" height="40"
                        width="40">

                </div>
            </div>
        </section>
        <section>
            <div class="container-home container-sm mt-3 mx-auto">
                <div class="row d-flex  justify-content-center p-3">
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>



        <section class="container  mt-5 py-4 mx-auto">
            <div class=" container ">
                <h2 class="fw-bold colorMovistar mb-4 text-center fs-42">Beneficios de contratar Internet hogar Movistar
                </h2>
                <div
                    class="row d-flex flex-column flex-md-row  justify-content-between align-items-center p-2 p-md-0 gap-5 ">
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <div>
                            <h3 class="text-black fs-3 fw-bold">Máxima velocidad</h3>
                            <p class="text-black fs-6 mt-2  "> Con la fibra óptica de Movistar, los usuarios pueden
                                disfrutar
                                de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                                descargas rápidas y una experiencia de streaming sin interrupciones.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Conexión más segura y estable</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica garantiza una conexión a Internet más
                                estable y
                                segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                                usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o
                                disfrutar
                                de juegos en línea sin sufrir cortes o retrasos.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4  ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Más dispositivos conectados</h3>
                            <p class="text-black fs-6 mt-2 ">Los planes de Internet hogar de Movistar permiten la
                                conexión de
                                hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                                conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                                dispositivos conectados simultáneamente.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-internet-wifi.svg" height="40"
                            width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Velocidad simétrica</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica de Movistar ofrece una velocidad
                                simétrica,
                                es
                                decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                                conexión a Internet, lo que resulta especialmente útil para actividades que requieren
                                una
                                alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-gray mt-5 py-4">
            <div class="container">
                <h2 class="fw-bold colorMovistar mb-4 text-center text-md-start">Nuestros Planes incluyen</h2>
                <div class="d-flex justify-content-between gap- justify-content-center4 flex-column flex-md-row">
                    <div class="row  containerrr">
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Internet</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img class="" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">App Smart Wifi</span>
                                        <p class="text-black mb-0 fs08">Administra tu red fácilmente</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Instalación sin costo en 48 horas</span>
                                        <p class="text-black mb-0 fs08">Podrás agendar la instalación con un ejecutivo
                                            cuando
                                            más
                                            te acomode.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Television</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/decodificador_azul.svg"
                                        alt="decodificador" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">1 decodificador incluido con Movistar
                                            TV</span>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/iconTV.svg" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">101 canales únicos (89 HD).</span>
                                        <p class="text-black mb-0 fs08">9 abiertos (7 HD)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-5">
                            <h4 class="text-black mb-2 fw-bold">Streaming</h4>
                            <div class="row">
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/Icon_disney.svg" alt="decodificador"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Disney+</span>
                                        <p class="text-black mb-0 fs08">Suscribete y disfruta lo mejor de Disney, Pixar,
                                            Marvel, Star Wars y National Geographic.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/Icon_paramount.svg" height="40"
                                        width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Paramount+</span>
                                        <p class="text-black mb-0 fs08">Disfruta de historias únicas, estrellas icónicas
                                            y
                                            programas exclusivos que no encontrarás en ningún otro lugar.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/Icon_star.svg" alt="decodificador"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Star+</span>
                                        <p class="text-black mb-0 fs08">Estrenos de películas, clásicos, sagas, series
                                            exclusivas, comedias animadas, producciones
                                            originales y deportes en vivo de ESPN.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div
                        class=" bg-white p-4 d-flex flex-column justify-content-around align-items-center rounded-5 mt-md-0 mt-4 ">
                        <div>
                            <h1 class="colorMovistar fs-5 text-center mb-0">Si eres cliente Movistar</h1>
                            <p class="text-black text-center fs-5">suma experiencias a tu plan</p>
                        </div>
                        <div class="d-flex justify-content-between flex-column gap-2">
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/amazonPrimeVideo.svg" height="40"
                                    width="40">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">El primer mes invita </h5>
                                    <p class="fs-12 text-black">A partir del mes 2 pagas $5.790 al mes.</p>
                                </div>
                            </div>
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/lionsgate.svg" height="24" width="40">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $7.490 mensual</h5>
                                    <p class="fs-12 text-black">Incluye <span>
                                            <img src="../../../assets/img/hbomax.svg" height="24" width="40">
                                        </span></p>
                                </div>
                            </div>
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/HBOpack.svg" height="40" width="40">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $6.990 mensual</h5>
                                    <p class="fs-12 text-black">Incluye HBO max.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </section>

    </div>

    <div *ngIf="this.router.url.includes('/PacksDuosStar')" class="planes-includes">
        <section
            class="bg-gray p-md-3 p-2 d-flex flex-md-row flex-column align-items-center justify-content-center w-100 text-center gap-md-5 gap-2">
            <h2 class="fw-bolder text-black fs-3 mb-0 d-md-block d-none">Contrata la fibra simetrica más rápida de
                Chile</h2>
            <div class="d-flex flex-row align-items-center justify-content-between gap-md=4 gap-2 w-full">
                <p class="fs-5 text-black mb-0">Todos los planes incluyen</p>
                <div class="d-flex gap-2">
                    <img class="rounded-4" src="../../../assets/img/Iconos Landing/Icon_paramount.svg" height="40"
                        width="40">

                    <img class="rounded-4" src="../../../assets/img/maxlogo.png" height="40" width="40">

                    <img src="../../../assets/img/Iconos Landing/logo_movistar_tv.png" height="40" width="40">


                </div>
            </div>
        </section>
        <section>
            <div class="container-home container-sm mt-3 mx-auto">
                <div class="row d-flex  justify-content-center">

                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>


        <section class="container mt-5">

            <h2 class="fw-bold colorMovistar mb-4 text-center fs-42">Compara sus beneficios </h2>
            <p class="text-black fs-6 mt-2 text-center  ">Descubre que tipo de Televisión se adapta mejor a tus
                necesidades</p>

            <div class="row d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">

                <div class="border border-aqua p-4  col-md-5 col-12">
                    <div class=" d-flex align-items-center">
                        <div class="bgMovistar px-4 py-1 rounded-4 text-white fs-5">
                            1
                        </div>
                        <h3 class="text-black ms-2 fs-5">
                            Movistar TV
                        </h3>

                    </div>
                    <div>
                        <p class="text-black fs-6 mt-2">
                            Televisión en alta definición desde decodificador, ideal para ver tu programación favorita
                            con la mejor calidad desde la comodidad de tu hogar.
                        </p>
                        <ul class="list-unstyled">
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/Iconos Landing/decodificador_azul.svg" height="30"
                                    width="30">
                                Televisión por decodificador
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/tv.svg" height="30" width="30">
                                Compatible con cualquier Televisor
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/play.svg" height="30" width="30">
                                <span>
                                    Graba, pausa y retrocede en vivo <span class="fw-bold">hasta 24 hrs.</span>
                                </span>
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/screen-4.svg" height="30" width="30">
                                <span class="fw-bold">Hasta 4 pantallas</span> en simultáneo*
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="border border-aqua p-4  col-md-5 col-12">
                    <div class=" d-flex align-items-center">
                        <div class="bgMovistar px-4 py-1 rounded-4 text-white fs-5">
                            2
                        </div>
                        <h3 class="text-black ms-2 fs-5">
                            Movistar TV App
                        </h3>
                    </div>
                    <div>
                        <p class="text-black fs-6 mt-2">
                            Descarga Movistar TV App en cualquiera de tus dispositivos y accede a su contenido donde
                            quiera que estés, solo necesitas una conexión a internet.
                        </p>
                        <ul class="list-unstyled">
                            <li class="d-flex align-content-center align-items-center gap-2"> <img
                                    src="../../../assets/img/iconosduo/app-mi-movistar.svg" height="30" width="30">
                                Televisión desde Aplicación
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2"> <img
                                    src="../../../assets/img/iconosduo/tv.svg" height="30" width="30">
                                Compatible con Smart TV, Teléfonos, Tablet u otros dispositivos compatibles
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/screen-3.svg" height="30" width="30">
                                <span class="fw-bold">Hasta 3 pantallas</span> en simultáneo*
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p class="text-black text-center fs-6 mt-2">
                *Con Movistar TV App, puedes disfrutar de tus contenidos en hasta 3 pantallas simultáneamente e
                instalarla en hasta 5 dispositivos compatibles. Además, al optar por Movistar TV, obtienes una pantalla
                adicional a través del decodificador incluido.
            </p>

        </section>



        <section class="container mt-5 py-4 mx-auto">
            <div class=" container ">
                <h2 class="fw-bold colorMovistar mb-4 text-center fs-42">Beneficios de contratar Internet hogar Movistar
                </h2>
                <div
                    class="row d-flex flex-column flex-md-row  justify-content-between align-items-center p-2 p-md-0 gap-5 ">
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <div>
                            <h3 class="text-black fs-3 fw-bold">Máxima velocidad</h3>
                            <p class="text-black fs-6 mt-2  "> Con la fibra óptica de Movistar, los usuarios pueden
                                disfrutar
                                de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                                descargas rápidas y una experiencia de streaming sin interrupciones.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Conexión más segura y estable</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica garantiza una conexión a Internet más
                                estable y
                                segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                                usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o
                                disfrutar
                                de juegos en línea sin sufrir cortes o retrasos.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4  ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Más dispositivos conectados</h3>
                            <p class="text-black fs-6 mt-2 ">Los planes de Internet hogar de Movistar permiten la
                                conexión de
                                hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                                conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                                dispositivos conectados simultáneamente.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-internet-wifi.svg" height="40"
                            width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Velocidad simétrica</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica de Movistar ofrece una velocidad
                                simétrica,
                                es
                                decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                                conexión a Internet, lo que resulta especialmente útil para actividades que requieren
                                una
                                alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section class="container mt-5 py-4 mx-auto">
            <div class=" container ">
                <h2 class="fw-bold colorMovistar mb-4 text-start fs-42 ">Descubre los mejores planes de Movistar
                    internet hogar en Chile
                </h2>
                <p class="text-start fs-6">Movistar Internet Hogar en Chile ofrece planes de alta velocidad
                    y calidad de
                    conexión.
                    Disfruta de
                    estabilidad, seguridad y conexión simétrica para una mejor experiencia. Además, aprovecha
                    promociones y descuentos especiales. Combina tus servicios de Internet y TV con paquetes variados y
                    amplio contenido. Obtén soporte y servicios exclusivos. La instalación es sencilla y cuenta con
                    opciones de configuración personalizada. Resuelve tus dudas con nuestras preguntas frecuentes.
                    Descubre todo lo que Movistar Internet Hogar tiene para ofrecerte en Chile.
                </p>
            </div>
            <h3 class="fw-bold text-black fs-2 mt-5">Movistar Internet Hogar en Chile</h3>
            <p class="fs-6">
                Movistar Internet Hogar en Chile ofrece una amplia variedad de planes diseñados para satisfacer las
                necesidades de cada usuario en términos de conectividad en el hogar. Desde planes básicos hasta opciones
                de alta velocidad, Movistar se posiciona como una de las mejores opciones en el mercado chileno.
            </p>
            <div class="row mt-5 flex flex-col gap-md-0 gap-3">
                <div class="col-md-6 col-12 d-flex flex-column">
                    <h4 class="text-black fs-3">Planes de Movistar Internet Hogar</h4>
                    <p class="fs-6">Los planes de Movistar Internet Hogar en Chile están diseñados para adaptarse a
                        diferentes
                        necesidades de conexión y presupuestos. Ofrecen velocidades que van desde los 600 megas hasta
                        los 2.000 megas, brindando opciones para que cada usuario pueda elegir la velocidad que mejor se
                        ajuste a sus requerimientos.</p>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column">
                    <h4 class="text-black fs-3">Velocidad y calidad de la conexión</h4>
                    <p class="fs-6">La conexión de Movistar Internet Hogar en Chile se destaca por su alta velocidad y
                        calidad.
                        Gracias al uso de la fibra óptica, se garantiza una experiencia de navegación fluida y rápida.
                        Esto permite disfrutar de transmisiones en vivo, juegos en línea y descargas sin interrupciones
                        ni retrasos.</p>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column mt-md-3">
                    <h4 class="text-black fs-3">Estabilidad y seguridad de la conexión</h4>
                    <p class="fs-6">La estabilidad y la seguridad son aspectos fundamentales en la conexión a Internet
                        en el hogar.
                        Movistar Internet Hogar en Chile ofrece conexiones estables y seguras gracias al uso de la fibra
                        óptica. Esto garantiza una transmisión de señal sin interferencias y protege la conexión de
                        posibles amenazas externas.</p>
                </div>
                <div class="col-md-6 col-12  col d-flex flex-column mt-md-3 ">
                    <h4 class="text-black fs-3">Conexión simétrica y beneficios adicionales</h4>
                    <p class="fs-6">Uno de los beneficios destacados de los planes de Movistar Internet Hogar en Chile
                        es la conexión
                        simétrica, que proporciona la misma velocidad tanto de descarga como de subida de datos. Esto es
                        especialmente útil para actividades como la transmisión de contenido en alta definición y el
                        trabajo remoto. Además, al contratar estos planes, se accede a beneficios adicionales en música,
                        cine, comida, belleza y más..</p>
                </div>
            </div>
            <h3 class="text-black fw-bold fs-2 mt-5">
                <strong>Planes de Movistar Hogar Internet y TV</strong>
            </h3>
            <div class="row mt-5 flex flex-col gap-3 gap-md-0">
                <div class="col-md-6 col-12 d-flex flex-column">
                    <h3 class="text-black fs-3">Paquetes combinados de Internet y TV</h3>
                    <p class="fs-6">En Movistar Hogar Internet y TV en Chile, tienes la opción de elegir entre una
                        variedad de paquetes que combinan servicios de Internet y televisión. Estos paquetes te brindan
                        una experiencia completa de entretenimiento en tu hogar, con la posibilidad de disfrutar de una
                        conexión de alta velocidad y una amplia selección de canales y contenido.
                    </p>
                </div>
                <div class="col-md-6 col-12 d-flex flex-column">
                    <h3 class="text-black fs-3">Variedad de canales y contenido</h3>
                    <p class="fs-6">Con los planes de Movistar Hogar Internet y TV, puedes acceder a una gran variedad
                        de canales en vivo, incluyendo opciones locales e internacionales, deportes, películas, series y
                        programas de entretenimiento. Además, tienes la opción de disfrutar de contenidos a la carta,
                        para ver tus películas y programas favoritos cuando quieras.</p>
                </div>
                <div class="col-12  d-flex flex-column mt-md-3 ">
                    <h3 class="text-black fs-3">Funciones y opciones de entretenimiento</h3>
                    <p class="fs-6 align-self-start">Además de los canales tradicionales, los planes de Movistar Hogar
                        Internet y TV te ofrecen funciones y opciones de entretenimiento adicionales. Puedes contar con
                        la posibilidad de pausar, retroceder o grabar tus programas favoritos, para no perderte ni un
                        solo momento. También, puedes acceder a plataformas de streaming y aplicaciones populares, para
                        disfrutar de contenido en línea de tus servicios preferidos.
                    </p>
                </div>
            </div>


        </section>

        <section class="bg-gray mt-5 py-4">
            <div class="container">
                <h2 class="fw-bold colorMovistar mb-4 text-center text-md-start">Nuestros Planes incluyen</h2>
                <div class="d-flex justify-content-between gap- justify-content-center4 flex-column flex-md-row">
                    <div class="row  containerrr">
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Internet</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img class="" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">App Smart Wifi</span>
                                        <p class="text-black mb-0 fs08">Administra tu red fácilmente</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Instalación sin costo en 48 horas</span>
                                        <p class="text-black mb-0 fs08">Podrás agendar la instalación con un ejecutivo
                                            cuando
                                            más
                                            te acomode.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Television</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/decodificador_azul.svg"
                                        alt="decodificador" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">1 decodificador incluido con Movistar
                                            TV</span>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/iconTV.svg" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">101 canales únicos (89 HD).</span>
                                        <p class="text-black mb-0 fs08">9 abiertos (7 HD)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-5">
                            <h4 class="text-black mb-2 fw-bold">Streaming</h4>
                            <div class="row">
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/Icon_disney.svg" alt="decodificador"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Disney+</span>
                                        <p class="text-black mb-0 fs08">Suscribete y disfruta lo mejor de Disney, Pixar,
                                            Marvel, Star Wars y National Geographic.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/Icon_paramount.svg" height="40"
                                        width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Paramount+</span>
                                        <p class="text-black mb-0 fs08">Disfruta de historias únicas, estrellas icónicas
                                            y
                                            programas exclusivos que no encontrarás en ningún otro lugar.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/maxlogo.png" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Max</span>
                                        <p class="text-black mb-0 fs08">Impresionantes series originales, películas,
                                            contenido basado en la vida real, los favoritos de la familia y tus
                                            películas preferidas de Harry Potter, el Universo DC y HBO.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/logo_movistar_tv.png" height="40"
                                        width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Movistar TV</span>
                                        <p class="text-black mb-0 fs08">Con 101 canales en vivo y en todos tus
                                            dispositivos.
                                        </p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <div
                        class=" bg-white p-4 d-flex flex-column justify-content-around align-items-center rounded-5 mt-md-0 mt-4 ">
                        <div>
                            <h1 class="colorMovistar fs-5 text-center mb-0">Si eres cliente Movistar</h1>
                            <p class="text-black text-center fs-5">suma experiencias a tu plan</p>
                        </div>
                        <div class="d-flex justify-content-between flex-column gap-2">
                            <div class="d-flex  gap-4">
                                <img src="../../../assets/img/Iconos Landing/primelogo.png" height="50" width="50">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">El primer mes invita Movistar </h5>
                                    <p class="fs-12 text-black">A partir del mes 2 pagas $5.790 al mes.</p>
                                </div>
                            </div>
                            <div class="d-flex  gap-4">
                                <img src="../../../assets/img/Iconos Landing/espnlogo.png" height="30" width="50">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $9.990 mensual</h5>

                                </div>
                            </div>
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/TNTSportsPremium2024.svg" height="50"
                                    width="50">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $12.490/mes
                                    </h5>
                                    <p class="fs-12 text-black">Incluye acceso <span>
                                            <img src="../../../assets/img/Iconos Landing/eSTADIO.png" height="20"
                                                width="60">
                                        </span></p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </section>

    </div>


    <div *ngIf="this.router.url.includes('/PacksDuosTlf')">
        <section>
            <div class="container mt-3 planes-includes">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 col-md-6 card border text-center d-inline mb-4 py-1 px-0">
                        <button class="btn btn-menu text-white rounded-5" [routerLink]="['PacksDuosCombo']"
                            routerLinkActive="btn-menu-active">Dúo TV Combo+</button>
                        <button class="btn btn-menu text-white rounded-5" [routerLink]="['PacksDuosStar']"
                            routerLinkActive="btn-menu-active">Dúo Star+</button>
                        <!-- <button class="btn btn-menu text-white rounded-5" [routerLink]="['PacksDuosTlf']" routerLinkActive="btn-menu-active">Dúo Telefonía</button> -->
                    </div>
                    <div class="col-12 text-center mb-4">
                        <h1 class="fw-bold">Internet + Telefonía</h1>
                        <p class="h5">
                            Todos nuestros planes incluyen Paramount+
                        </p>
                    </div>
                    <!-- <div class="col-12">
                        <p>
                            &nbsp;: <br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde aspernatur
                            est assumenda
                        </p>
                    </div> -->
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>



        <section class="container mt-5 py-4">
            <div class="contianer">
                <h2 class="fw-bold colorMovistar mb-4 text-center">Beneficios de contratar Internet hogar Movistar</h2>
                <div class="row d-flex flex-column flex-md-row  p-2 p-md-0 ">
                    <div class="col-12 col-md-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <h3 class="text-black fs-3">Máxima velocidad:</h3>
                        <p class="text-black fs-6 mt-2"> Con la fibra óptica de Movistar, los usuarios pueden disfrutar
                            de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                            descargas rápidas y una experiencia de streaming sin interrupciones.
                        </p>
                    </div>
                    <div class="col-12 col-md-4">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <h3 class="text-black fs-3">Conexión más segura y estable:</h3>
                        <p class="text-black fs-6 mt-2"> La fibra óptica garantiza una conexión a Internet más estable y
                            segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                            usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o disfrutar
                            de juegos en línea sin sufrir cortes o retrasos.
                        </p>
                    </div>
                    <div class="col-12 col-md-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <h3 class="text-black fs-3">Más dispositivos conectados:</h3>
                        <p class="text-black fs-6 mt-2">Los planes de Internet hogar de Movistar permiten la conexión de
                            hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                            conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                            dispositivos conectados simultáneamente.
                        </p>
                    </div>
                    <div class="col-12 col-md-4">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <h3 class="text-black fs-3">Velocidad simétrica:</h3>
                        <p class="text-black fs-6 mt-2"> La fibra óptica de Movistar ofrece una velocidad simétrica, es
                            decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                            conexión a Internet, lo que resulta especialmente útil para actividades que requieren una
                            alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                        </p>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-light mt-5 pt-4 planes-includes">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 text-center mb-5">
                        <h1 class="colorMovistar fs-32 fw-bold">Todos nuestros planes incluyen</h1>
                    </div>

                    <div class="col-12 col-md-4 text-center">
                        <h2 class="colorMovistar fs-32">Internet</h2>

                        <br>

                        <img src="../../../assets/img/Iconos Landing/smart_wifi.svg" alt="smart_Wifi" height="40"
                            width="40">
                        <h6 class="fs-24 fw-bold mt-3">App Smart Wifi</h6>
                        <p class="fs-24">Administra tu red de manera simple.</p>

                        <br>

                        <img src="../../../assets/img/Iconos Landing/InstalacionSinCosto.svg" alt="InstalacionSinCosto"
                            height="40" width="40">
                        <h6 class="fs-24 fw-bold mt-3">Instalación sin costo</h6>
                        <p class="fs-24">&nbsp;</p>
                    </div>

                    <div class="col-12 col-md-4 text-center">
                        <h2 class="colorMovistar fs-32">Telefonía</h2>

                        <br>

                        <img src="../../../assets/img/Iconos Landing/telefonoMinutos.svg" alt="Telefono" height="40"
                            width="40">
                        <h6 class="fs-24 fw-bold mt-3"> Minutos fijos Ilimitados</h6>
                        <p class="fs-24">300 a móviles</p>
                    </div>

                    <div class="col-12 col-md-4 text-center">
                        <h2 class="colorMovistar fs-32">Streaming</h2>
                        <br>
                        <img src="../../../assets/img/Iconos Landing/movistar_play.svg" alt="movistar_play" height="40"
                            width="40">
                        <h6 class="fs-24 fw-bold ms-3 mb-0">Multipantalla Free<br>
                            <span class="fs-24 fw-light">Con 8 canales en vivo en todos tus dispositivos</span>
                        </h6>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-light mt-5 pt-4">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-12 text-center mb-3">
                        <h5 class="fw-light"><span class="colorMovistar fw-bold">Si eres cliente Movistar</span> suma
                            experiencias a tu plan</h5>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <img src="../../../assets/img/Iconos Landing/amazonPrimeVideo.svg" height="40" width="40">
                        <h5 class="h6 fw-bold m-0 p-0">El primer mes invita </h5>
                        <p class="colorMovistar fs-12">A partir del mes 2 pagas $5.790 al mes.</p>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <img src="../../../assets/img/Iconos Landing/HBOpack.svg" height="40" width="40">
                        <h5 class="h6 fw-bold m-0 p-0">Paga $6.990 mensual.</h5>
                        <p class="colorMovistar fs-12">Incluye HBO max.</p>
                    </div>
                    <div class="col-12 col-md-2 text-center">
                        <img src="../../../assets/img/Iconos Landing/lionsgate.svg" height="40" width="40">
                        <h5 class="h6 fw-bold m-0 p-0">Paga $7.490 mensual</h5>
                        <p class="colorMovistar fs-12">&nbsp;< </div>
                    </div>
                </div>
        </section>

    </div>

    <!-- Planes Trios -->

    <div *ngIf="this.router.url.includes('/PacksTriosCombo')">
        <section
            class="bg-gray p-md-3 p-2 d-flex flex-md-row flex-column align-items-center justify-content-center w-100 text-center gap-md-5 gap-2">
            <h2 class="fw-bolder text-black fs-3 mb-0 d-md-block d-none">Contrata la fibra simetrica más rápida de
                Chile</h2>
            <div class="d-flex flex-row align-items-center justify-content-between gap-md=4 gap-2 w-full">
                <p class="fs-5 text-black mb-0">Todos los planes incluyen</p>
                <div class="d-flex gap-2">
                    <img class="rounded-4" src="../../../assets/img/Iconos Landing/Icon_paramount.svg" height="40"
                        width="40">

                    <img class="rounded-4" src="../../../assets/img/maxlogo.png" height="40" width="40">
                    <img src="../../../assets/img/Iconos Landing/logo_movistar_tv.png" height="40" width="40">

                </div>
            </div>
        </section>
        <section>
            <div class="container-home container-sm mt-3 mx-auto">
                <div class="row d-flex  justify-content-center">

                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>

        <section class="container mt-5">

            <h2 class="fw-bold colorMovistar mb-4 text-center fs-42">Compara sus beneficios </h2>
            <p class="text-black fs-6 mt-2 text-center  ">Descubre que tipo de Televisión se adapta mejor a tus
                necesidades</p>

            <div class="row d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">

                <div class="border border-aqua p-4  col-md-5 col-12">
                    <div class=" d-flex align-items-center">
                        <div class="bgMovistar px-4 py-1 rounded-4 text-white fs-5">
                            1
                        </div>
                        <h3 class="text-black ms-2 fs-5">
                            Movistar TV
                        </h3>

                    </div>
                    <div>
                        <p class="text-black fs-6 mt-2">
                            Televisión en alta definición desde decodificador, ideal para ver tu programación favorita
                            con la mejor calidad desde la comodidad de tu hogar.
                        </p>
                        <ul class="list-unstyled">
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/Iconos Landing/decodificador_azul.svg" height="30"
                                    width="30">
                                Televisión por decodificador
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/tv.svg" height="30" width="30">
                                Compatible con cualquier Televisor
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/play.svg" height="30" width="30">
                                <span>
                                    Graba, pausa y retrocede en vivo <span class="fw-bold">hasta 24 hrs.</span>
                                </span>
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/screen-4.svg" height="30" width="30">
                                <span class="fw-bold">Hasta 4 pantallas</span> en simultáneo*
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="border border-aqua p-4 col-md-5 col-12">
                    <div class=" d-flex align-items-center">
                        <div class="bgMovistar px-4 py-1 rounded-4 text-white fs-5">
                            2
                        </div>
                        <h3 class="text-black ms-2 fs-5">
                            Movistar TV App
                        </h3>
                    </div>
                    <div>
                        <p class="text-black fs-6 mt-2">
                            Descarga Movistar TV App en cualquiera de tus dispositivos y accede a su contenido donde
                            quiera que estés, solo necesitas una conexión a internet.
                        </p>
                        <ul class="list-unstyled">
                            <li class="d-flex align-content-center align-items-center gap-2"> <img
                                    src="../../../assets/img/iconosduo/app-mi-movistar.svg" height="30" width="30">
                                Televisión desde Aplicación
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2"> <img
                                    src="../../../assets/img/iconosduo/tv.svg" height="30" width="30">
                                Compatible con Smart TV, Teléfonos, Tablet u otros dispositivos compatibles
                            </li>
                            <li class="d-flex align-content-center align-items-center gap-2">
                                <img src="../../../assets/img/iconosduo/screen-3.svg" height="30" width="30">
                                <span class="fw-bold">Hasta 3 pantallas</span> en simultáneo*
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p class="text-black text-center fs-6 mt-2">
                *Con Movistar TV App, puedes disfrutar de tus contenidos en hasta 3 pantallas simultáneamente e
                instalarla en hasta 5 dispositivos compatibles. Además, al optar por Movistar TV, obtienes una pantalla
                adicional a través del decodificador incluido.
            </p>

        </section>



        <section class="container  mt-5 py-4 mx-auto">
            <div class=" container ">
                <h2 class="fw-bold colorMovistar mb-4 text-center fs-42">Beneficios de contratar Internet hogar Movistar
                </h2>
                <div
                    class="row d-flex flex-column flex-md-row  justify-content-between align-items-center p-2 p-md-0 gap-5 ">
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <div>
                            <h3 class="text-black fs-3 fw-bold">Máxima velocidad</h3>
                            <p class="text-black fs-6 mt-2  "> Con la fibra óptica de Movistar, los usuarios pueden
                                disfrutar
                                de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                                descargas rápidas y una experiencia de streaming sin interrupciones.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Conexión más segura y estable</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica garantiza una conexión a Internet más
                                estable y
                                segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                                usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o
                                disfrutar
                                de juegos en línea sin sufrir cortes o retrasos.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4  ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Más dispositivos conectados</h3>
                            <p class="text-black fs-6 mt-2 ">Los planes de Internet hogar de Movistar permiten la
                                conexión de
                                hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                                conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                                dispositivos conectados simultáneamente.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 d-flex align-items-center gap-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-internet-wifi.svg" height="40"
                            width="40">
                        <div>

                            <h3 class="text-black fs-3 fw-bold">Velocidad simétrica</h3>
                            <p class="text-black fs-6 mt-2 "> La fibra óptica de Movistar ofrece una velocidad
                                simétrica,
                                es
                                decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                                conexión a Internet, lo que resulta especialmente útil para actividades que requieren
                                una
                                alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-gray mt-5 py-4">
            <div class="container">
                <h2 class="fw-bold colorMovistar mb-4 text-center text-md-start">Nuestros Planes incluyen</h2>
                <div class="d-flex justify-content-between gap- justify-content-center4 flex-column flex-md-row">
                    <div class="row  containerrr">
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Internet</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img class="" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">App Smart Wifi</span>
                                        <p class="text-black mb-0 fs08">Administra tu red fácilmente</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Instalación sin costo en 48 horas</span>
                                        <p class="text-black mb-0 fs08">Podrás agendar la instalación con un ejecutivo
                                            cuando
                                            más
                                            te acomode.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2 fw-bold">Television</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/decodificador_azul.svg"
                                        alt="decodificador" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">1 decodificador incluido con Movistar
                                            TV</span>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/iconTV.svg" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">101 canales únicos (89 HD).</span>
                                        <p class="text-black mb-0 fs08">9 abiertos (7 HD)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-5">
                            <h4 class="text-black mb-2 fw-bold">Streaming</h4>
                            <div class="row">
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/Icon_disney.svg" alt="decodificador"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Disney+</span>
                                        <p class="text-black mb-0 fs08">Suscribete y disfruta lo mejor de Disney, Pixar,
                                            Marvel, Star Wars y National Geographic.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/Icon_paramount.svg" height="40"
                                        width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Paramount+</span>
                                        <p class="text-black mb-0 fs08">Disfruta de historias únicas, estrellas icónicas
                                            y
                                            programas exclusivos que no encontrarás en ningún otro lugar.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/maxlogo.png" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Max</span>
                                        <p class="text-black mb-0 fs08">Impresionantes series originales, películas,
                                            contenido basado en la vida real, los favoritos de la familia y tus
                                            películas preferidas de Harry Potter, el Universo DC y HBO.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/logo_movistar_tv.png" height="40"
                                        width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Movistar TV</span>
                                        <p class="text-black mb-0 fs08">Con 101 canales en vivo y en todos tus
                                            dispositivos.
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div
                        class=" bg-white p-4 d-flex flex-column justify-content-around align-items-center rounded-5 mt-md-0 mt-4 ">
                        <div>
                            <h1 class="colorMovistar fs-5 text-center mb-0">Si eres cliente Movistar</h1>
                            <p class="text-black text-center fs-5">suma experiencias a tu plan</p>
                        </div>
                        <div class="d-flex justify-content-between flex-column gap-2">
                            <div class="d-flex  gap-4">
                                <img src="../../../assets/img/Iconos Landing/primelogo.png" height="50" width="50">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">El primer mes invita Movistar </h5>
                                    <p class="fs-12 text-black">A partir del mes 2 pagas $5.790 al mes.</p>
                                </div>
                            </div>
                            <div class="d-flex  gap-4">
                                <img src="../../../assets/img/Iconos Landing/espnlogo.png" height="30" width="50">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $9.990 mensual</h5>

                                </div>
                            </div>
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/TNTSportsPremium2024.svg" height="50"
                                    width="50">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $12.490/mes
                                    </h5>
                                    <p class="fs-12 text-black">Incluye acceso <span>
                                            <img src="../../../assets/img/Iconos Landing/eSTADIO.png" height="20"
                                                width="100">
                                        </span></p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </section>

    </div>

    <div *ngIf="this.router.url.includes('/PacksTriosStar')">
        <section>
            <div class="container-home container-sm mt-3 mx-auto">
                <div class="row d-flex  justify-content-center">

                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 my-2" *ngFor="let product of productos">
                        <app-caluga [type]="'hogar'" [producto]="product"></app-caluga>
                    </div>
                </div>
            </div>
        </section>


        <section class="container mt-5 py-4">
            <div class="contianer">
                <h2 class="fw-bold colorMovistar mb-4 text-center">Beneficios de contratar Internet hogar Movistar</h2>
                <div class="row d-flex flex-column flex-md-row  p-2 p-md-0 ">
                    <div class="col-12 col-md-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <h3 class="text-black fs-3">Máxima velocidad:</h3>
                        <p class="text-black fs-6 mt-2"> Con la fibra óptica de Movistar, los usuarios pueden disfrutar
                            de la conexión a Internet más rápida de Chile. Esto se traduce en una navegación fluida,
                            descargas rápidas y una experiencia de streaming sin interrupciones.
                        </p>
                    </div>
                    <div class="col-12 col-md-4">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-conexion-mas-segura-estable.svg"
                            height="40" width="40">
                        <h3 class="text-black fs-3">Conexión más segura y estable:</h3>
                        <p class="text-black fs-6 mt-2"> La fibra óptica garantiza una conexión a Internet más estable y
                            segura, evitando interferencias en la transmisión de la señal. Esto significa que los
                            usuarios pueden realizar transmisiones en vivo, participar en videoconferencias o disfrutar
                            de juegos en línea sin sufrir cortes o retrasos.
                        </p>
                    </div>
                    <div class="col-12 col-md-4 ">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-mas-dispositivos-conectados.svg"
                            height="40" width="40">
                        <h3 class="text-black fs-3">Más dispositivos conectados:</h3>
                        <p class="text-black fs-6 mt-2">Los planes de Internet hogar de Movistar permiten la conexión de
                            hasta 32 dispositivos al mismo tiempo, sin que esto afecte la velocidad o calidad de la
                            conexión a Internet. Esto resulta especialmente beneficioso en hogares con múltiples
                            dispositivos conectados simultáneamente.
                        </p>
                    </div>
                    <div class="col-12 col-md-4">
                        <img class="mb-2" src="../../../assets/img/Iconos Landing/ico-maxima-velocidad.svg" height="40"
                            width="40">
                        <h3 class="text-black fs-3">Velocidad simétrica:</h3>
                        <p class="text-black fs-6 mt-2"> La fibra óptica de Movistar ofrece una velocidad simétrica, es
                            decir, la misma velocidad de descarga y subida. Esto se traduce en una mejor calidad de
                            conexión a Internet, lo que resulta especialmente útil para actividades que requieren una
                            alta velocidad de subida, como videoconferencias o subida de archivos a la nube.
                        </p>
                    </div>
                </div>
            </div>

        </section>

        <section class="bg-light mt-5 py-4 planes-includes">
            <div class="container">
                <h2 class="fw-bold colorMovistar mb-4 text-center">Nuestros Planes incluyen</h2>
                <div class="d-flex justify-content-between gap- justify-content-center4 flex-column flex-md-row">
                    <div class="row containerrr">
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2">Internet</h4>
                            <div class="row">
                                <div class="col-12 d-flex gap-4 align-items-center mb-4">
                                    <img class="" src="../../../assets/img/Iconos Landing/app-smart-wifi 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">App Smart Wifi</span>
                                        <p class="text-black mb-0 fs08">Administra tu red fácilmente</p>
                                    </div>
                                </div>
                                <div class="col-12 d-flex  gap-4 align-items-center">
                                    <img class="me-2" src="../../../assets/img/Iconos Landing/icon_instalacion 1.svg"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Instalación sin costo en 48 horas</span>
                                        <p class="text-black mb-0 fs08">Podrás agendar la instalación con un ejecutivo
                                            cuando
                                            más
                                            te acomode.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-3 mt-md-0">
                            <h4 class="text-black mb-2">Television + Telefonía</h4>
                            <div class="row d-flex flex-column gap-4">
                                <div class="col-12 d-flex gap-4 align-items-center mb-2">
                                    <img src="../../../assets/img/Iconos Landing/decodificador_azul.svg"
                                        alt="decodificador" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0 fs-16">1 decodificador incluido con
                                            Movistar TV</span>

                                    </div>
                                </div>
                                <div class="col-12 d-flex gap-4 align-items-center mb-2">
                                    <img src="../../../assets/img/Iconos Landing/telefonoMinutos.svg"
                                        alt="decodificador" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Minutos fijos Ilimitados</span>

                                    </div>
                                </div>
                                <div class="col-12 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/iconTV.svg" height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">101 canales únicos (89 HD).</span>
                                        <p class="text-black mb-0 fs08">9 abiertos (7 HD)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <h4 class="text-black mb-2">Streaming</h4>
                            <div class="row">
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/Icon_disney.svg" alt="decodificador"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Disney+</span>
                                        <p class="text-black mb-0 fs08">Suscribete y disfruta lo mejor de Disney, Pixar,
                                            Marvel, Star Wars y National Geographic.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center">
                                    <img src="../../../assets/img/Iconos Landing/Icon_paramount.svg" height="40"
                                        width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Paramount+</span>
                                        <p class="text-black mb-0 fs08">Disfruta de historias únicas, estrellas icónicas
                                            y
                                            programas exclusivos que no encontrarás en ningún otro lugar.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 d-flex gap-4 align-items-center mb-4">
                                    <img src="../../../assets/img/Iconos Landing/Icon_star.svg" alt="decodificador"
                                        height="40" width="40">
                                    <div>
                                        <span class="fw-bold text-black mb-0">Star+</span>
                                        <p class="text-black mb-0 fs08">Estrenos de películas, clásicos, sagas, series
                                            exclusivas, comedias animadas, producciones
                                            originales y deportes en vivo de ESPN.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div
                        class="cliente-container p-4 d-flex flex-column justify-content-around align-items-center rounded-5 ">
                        <div>
                            <h1 class="colorMovistar fs-5 text-center mb-0">Si eres cliente Movistar</h1>
                            <p class="text-black text-center fs-5">suma experiencias a tu plan</p>
                        </div>
                        <div class="d-flex justify-content-between flex-column gap-2">
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/amazonPrimeVideo.svg" height="40"
                                    width="40">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">El primer mes invita </h5>
                                    <p class="fs-12 text-black">A partir del mes 2 pagas $5.790 al mes.</p>
                                </div>
                            </div>
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/lionsgate.svg" height="24" width="40">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $7.490 mensual</h5>
                                    <p></p>
                                </div>
                            </div>
                            <div class="d-flex text-center gap-4">
                                <img src="../../../assets/img/Iconos Landing/HBOpack.svg" height="40" width="40">
                                <div>
                                    <h5 class="h6 fw-bold m-0 p-0 text-black">Paga $6.990 mensual</h5>
                                    <p class="fs-12 text-black">Incluye HBO max.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </section>

    </div>