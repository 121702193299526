import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Caluga } from 'src/app/commons/interfaces/caluga';
import { ProductService } from 'src/app/commons/service/product.service';

@Component({
  selector: 'app-products-movil',
  templateUrl: './products-movil.component.html',
  styleUrls: ['./products-movil.component.scss']
})
export class ProductsMovilComponent implements OnInit {

  cuantity: number = 1;
  productos: Caluga[] = [];
  category: string;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0)
    this.route.url.subscribe((params: any) => {
    })
    this.route.firstChild.params.subscribe((params: any) => {
      this.category = params["parameter"];
      switch (params["parameter"]) {
        case "PlanesIndividuales":
          this.getProducts(1);
          break;
        case "Planes2x":
          this.getProducts(3);
          break;
        case "PlanesEquipo":
          this.getProducts(1);
          break;
        default:
          this.getProducts(1);
          break;
      }
    });
  }

  getProducts(category) {
    this.productos = [];
    this.productService.getProducts(category, '1', 'TODOS', 'TODOS').subscribe((planes: Caluga[]) => {
      if (!planes || planes.length <= 0) {
        return;
      }
      planes.forEach((producto: Caluga) => {
        if (producto) {
          this.productos.push(producto);
        }
      });
    });
  }
  aumentQuantity() {
    if (this.cuantity < 2) {
      this.cuantity++;
    }
  }

  reduceQuantity() {
    if (this.cuantity > 1) {
      this.cuantity--;
    }
  }
}
