<app-banner [url]="'/movil/PlanesIndividuales/'"></app-banner>
<div class="container mt-3 ">
    <p>
        <b>
            Terminos y condiciones Movil:
        </b>
    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li>
                <p>
                    Conoce los
                    <a href="https://ww2.movistar.cl/movil/indicadores-telefonia-movil/" target="_blank">
                        indicadores de velocidad de navegación móvil
                    </a>
                </p>
            </li>
            <li>
                <p>

                </p>
            </li>
            <li>
                <p>
                    Plan 5G Libre Inicia $9.990 ($1.000 de descuento por 6 meses), Plan 5G Libre Full, Pro y Ultra (20%
                    de descuento por 6 meses), desde mes 7 se pagará valor normal de cada plan mencionado. Válido desde
                    el 01 al 31 de agosto de 2024.
                </p>
            </li>
            <li>
                <p>
                    Monolíneas:

                    Precios informados podrán ser reajustados de acuerdo al IPC según se detalla en contratos de
                    servicios. Los descuentos aplicados se mantendrán siempre y cuando el cliente mantenga el mismo plan
                    contratado. Movistar pondrá a disposición de todos sus clientes que cuenten con un equipo compatible
                    con tecnología 5G, la posibilidad de acceder a esta red en las zonas de cobertura, sin cargos
                    adicionales
                </p>
            </li>
            <li>
                <p>
                    Exclusiva para nuevas contrataciones Monolíneas, válida para el Plan 5G Ultra con 20% de descuento
                    por 6 meses, desde mes 7 pagará valor normal del plan publicado.
                </p>
            </li>

            <li>
                <p>
                    Precios informados podrán ser reajustados de acuerdo al IPC según se detalla en contratos de
                    servicios. Los descuentos aplicados se mantendrán siempre y cuando el cliente mantenga el mismo plan
                    contratado. Movistar pondrá a disposición de todos sus clientes que cuenten con un equipo compatible
                    con tecnología 5G, la posibilidad de acceder a esta red en las zonas de cobertura, sin cargos
                    adicionales
                </p>

            <li>
                <p>
                    Plan 5G Libre Inicia: Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los
                    usuarios del plan 5G Libre Inicia se le podría reducir la velocidad máxima de navegación de bajada
                    hasta 128 kbps, en horario y/o lugares de congestión, en el evento de que su consumo de datos haya
                    sobrepasado los 100 GB.
                </p>
            </li>
            <li>
                <p>
                    Plan 5G Libre Full: Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los
                    usuarios del plan 5G Libre Full se le podría reducir la velocidad máxima de navegación de bajada
                    hasta 128 kbps, en horario y/o lugares de congestión, en el evento de que su consumo de datos haya
                    sobrepasado los 200 GB.
                </p>
            </li>
            <li>
                Plan 5G Libre Pro: Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los
                usuarios del plan 5G Libre Pro se le podría reducir la velocidad máxima de navegación de bajada hasta 1
                Mbps, en horario y/o lugares de congestión, en el evento de que su consumo de datos haya sobrepasado los
                300 GB.
            </li>
            <li>
                Plan 5G Libre Ultra: Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los
                usuarios del Plan 5G Libre Ultra se le podría reducir la velocidad máxima de navegación de bajada hasta
                1 Mbps, en horario y/o lugares de congestión, en el evento de que su consumo de datos haya sobrepasado
                los 450GB.
            </li>
            <li>
                <p>
                    Los minutos libres incluidos en el respectivo plan, aplican sólo para un máximo de 300 destinatarios
                    por mes. Tanto los minutos generados a destinatarios adicionales, como las comunicaciones a redes de
                    compañías cuyas tarifas de cargo de acceso sean superiores a las de Movistar, no se encuentran
                    incluidas en la renta del Plan contratado y tendrán el valor consignado en el anexo “Listado de
                    servicios y cobros”.
                </p>
            </li>
            <li>
                <p>
                    Los mensajes de texto que excedan la cantidad de SMS incluidos en el plan, tendrán el valor
                    consignado en anexo “Listado de servicios y cobros”.
                </p>
            </li>

            <li>
                <p>
                    Para conocer las zonas con cobertura
                    <a href="https://ww2.movistar.cl/cobertura-movil/">
                        revisa aquí
                    </a>
                </p>
            </li>
            <li>
                <p>
                    Movistar pondrá a disposición de todos sus clientes que cuenten con un equipo compatible con
                    tecnología 5G, la posibilidad de acceder a esta red en las zonas de cobertura, sin cargos
                    adicionales.
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Planes con equipo nuevo:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Valor sujeto a contrato de arriendo con opción de compra a 18 meses.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Valor del equipo varia en función del plan que selecciones.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Valor cuota equipo varía de acuerdo a cada modelo.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - El costo de despacho de los equipos es de $2.990.
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Planes sin equipo:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Sólo disponible para clientes con equipo
                </p>
            </li>
            <li>
                <b>
                    Todas las tarifas incluyen IVA.
                </b>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/RED_servicio_BandaAnchaFijo.pdf"
                        target="_blank">
                        Ver las medidas de gestión de tráfico y administración de la red.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/politica_descuentos.pdf"
                        target="_blank">
                        Ver los procedimientos de descuentos e indemnización.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/terminos-regulaciones/condiciones-comerciales-y-contractuales-hogar/a"
                        target="_blank">
                        Ver todas las condiciones contractuales y comerciales.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="http://medicionesenlaces.movistar.cl/baf/?_ga=2.34685281.130507210.1494342865-1824513391.1494339959"
                        target="_blank">
                        Calidad y Disponibilidad de los Enlaces
                    </a>
                </p>
            </li>
        </ul>
    </div>
    <p>
        <b>
            Planes Multilínea
        </b>
    </p>
    <p>
        Vigencia desde el 01 al 31 de agosto de 2024

    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li class="noStyleList">
                <p>
                    Los Planes multilíneas permiten contratar hasta 4 líneas adicionales bajo un mismo RUT
                    .
                </p>
            </li>
            <li>
                <p>
                    Esta modalidad de contratación permite contratar una línea principal, que considerará el precio de
                    lista según plan contratado y una o más líneas adicionales que tendrán los valores detallados en la
                    tabla de planes multilínea.
                </p>
            </li>
            <li>
                <p>
                    La línea principal será aquella de mayor valor de cargo fijo, en caso de existir planes con
                    distintos precios. En caso de tratarse todas del mismo plan, la línea más antigua será considerada
                    la línea principal.

                </p>
            </li>
            <li class="">
                <p>
                    Los clientes que contraten planes multilínea mantendrán el valor promocional mientras mantengan más
                    de una línea contratada bajo el mismo RUT. En el caso de que el cliente de término a sus planes,
                    quedando como una sola línea independiente de que si la que mantiene es una línea adicional, ésta
                    tendrá el valor normal según plan asociado.

                </p>
            </li>
            <li class="">
                <p>
                    Los clientes que tengan un plan contratado con anterioridad a la vigencia de las presentes
                    condiciones comerciales, que deseen contratar un plan multilínea deberán modificar su plan y se
                    regirán desde ese momento por las presentes condiciones.
                </p>
            </li>
            <li>
                <p>
                    Movistar pondrá a disposición de todos sus clientes que cuenten con un equipo compatible con
                    tecnología 5G, la posibilidad de acceder a esta red en las zonas de cobertura, sin cargos
                    adicionales.

                </p>
            </li>
            <li>
                <p>
                    Movistar podrá realizar un cobro por concepto de solicitud de portabilidad. El costo de esta
                    solicitud se encuentra informado en el listado de servicios y cobros publicado en www.movistar.cl.
                </p>
            </li>
            <li>
                <p>
                    Está prohibido el uso y explotación comercial o industrial del servicio incluido en el plan
                    respectivo y también la explotación, reventa y/o comercialización de tráfico, que se genere en
                    ocasión o como consecuencia del uso de los servicios incluidos en el plan respectivo, cualquiera sea
                    su origen y/o destino.
                </p>
            </li>
            <li>
                <p>
                    Los Gigabytes del servicio incluidos en el "planes servicio móvil" y en el "plan sólo Datos", sólo
                    pueden ser utilizados por parte del cliente (suscriptor y/o usuario) en su teléfono móvil y nunca a
                    través de dispositivos como módems, punto hotspot, aparatos M2M, máquinas generadoras de trafico
                    automático y/o de uso fraudulento o a través de cualquier otro medio que permita el acceso y/o
                    compartir su uso a múltiples usuarios, excepto en el caso de que la conexión se comparta a través
                    del teléfono móvil.

                </p>
            </li>
            <li>
                <p>
                    Los equipos se conectarán en función de su tecnología a las distintas redes disponibles. (2G/3G/4G
                    LTE/5G), dependiendo del punto de cobertura por la cual transite el cliente y de las condiciones del
                    plan contratado.

                </p>
            </li>

        </ul>
    </div>
</div>
<app-footer-movil></app-footer-movil>