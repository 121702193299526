<footer class="bgAzulOscuro text-center text-lg-start">
    <div class="container">
        <div class="row d-flex justify-content-center align-items-center py-3">
            <div class="col-12 col-md-4 text-center">
                <img class="img-fluid border-end pe-2" src="../../../assets/img/Movistar_Logo_footer.svg" alt="" height="40" width="180">
                <span class="text-white ps-3 mt-1">Distribuidor Autorizado</span>
            </div>
            <div class="col-12 col-md-4 text-center mb-1">
                <a [routerLink]="['/sucursales']" routerLinkActive="router-link-active"  class="h6 text-white text-decoration-none">Sucursales Movistar</a>
            </div>
            <div class="col-12 col-md-4 text-center mb-1">
                <a [routerLink]="['/hogar/terminos-y-condiciones']" routerLinkActive="router-link-active"  class="h6 text-white text-decoration-none">Términos y Condiciones</a>
            </div>
        </div>
    </div>
</footer>
