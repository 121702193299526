<section class="sticky-top bg-white">

    <section class="container py-1">
      <div class="row d-inline-block">
        <a [routerLink]="['/hogar/InternetHogar']" class="text-decoration-none border-end">Hogar</a>
        <a [routerLink]="['/movil/PlanesIndividuales']" class="text-decoration-none border-end">Movil</a>
        <a [routerLink]="['/pre-plan/PlanesParaTodos']" class="text-decoration-none">Pre-Plan</a>
      </div>
    </section>
  
    <nav class="navbar navbar-expand-lg navbar-dark shadow bgMovistar pb-2" id="navbar-scrollHogar">
      <div class="container">
  
        <a class="navbar-brand fw-light text-dark rounded-5">
          <img src="../../../../assets/img/logo-movistar-white.svg" alt="Movistar_logo" width="150" height="30">
        </a>
  
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="mt-1 mb-3 d-none d-sm-none d-md-block">
            <button class="buttonGreen" [routerLink]="[url]" >Volver al sitio</button>
        </div>
      </div>
    </nav>
  </section>
  