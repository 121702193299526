import { Component, OnInit } from '@angular/core';
import { Sucursal } from '../interfaces/sucursal';
import { ProductService } from '../service/product.service';

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.scss']
})
export class SucursalesComponent implements OnInit {

  rmActive: boolean;
  nortActive: boolean;
  southActive: boolean;
  sucursales: Sucursal[];
  sucursalesNorte: Sucursal[];
  sucursalesRM: Sucursal[];
  sucursalesSur: Sucursal[];
  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.getSucursales();
  }
  changeCategory(idCategory) {
    switch (idCategory) {
      case '10':
        this.rmActive = true;
        this.nortActive = false;
        this.southActive = false;
        break;
      case '11':
        this.rmActive = false;
        this.nortActive = true;
        this.southActive = false;
        break;
      case '12':
        this.rmActive = false;
        this.nortActive = false;
        this.southActive = true;
        break;
    }
    this.sucursales = ((idCategory === '11') ? this.sucursalesNorte : (idCategory === '12') ? this.sucursalesSur : this.sucursalesRM);
  }
  getSucursales() {
    this.sucursales = [];
    this.productService.getSucursales().subscribe((res: any) => {
      this.sucursales = res;
      this.sucursalesNorte = this.sucursales.filter(sucursal => sucursal.category === '11')
      this.sucursalesRM = this.sucursales.filter(sucursal => sucursal.category === '10')
      this.sucursalesSur = this.sucursales.filter(sucursal => sucursal.category === '12')
    })
  }
}
