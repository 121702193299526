import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { FormService } from 'src/app/commons/service/form.service';
import { popupService } from 'src/app/commons/service/popup.service';
import { RutValidator } from 'src/app/commons/validators';

@Component({
  selector: 'app-home-hogar',
  templateUrl: './home-hogar.component.html',
  styleUrls: ['./home-hogar.component.scss']
})
export class HomeHogarComponent implements OnInit {

  public popUpForm: FormGroup

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  error = false;
  success = false;

  constructor
    (
      private idle: Idle,
      private fb: FormBuilder,
      private formService: FormService,
      private popupService: popupService

    ) {

    // var today = new Date().getHours();

    // idle.setIdle(10);
    // idle.setTimeout(10);
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => {
    //   this.idleState = 'No longer idle.'
    //   this.reset();
    // });

    // idle.onTimeout.subscribe(() => {
    //   this.idleState = 'Timed out!';
    //   this.timedOut = true;
    //   this.error = false;
    //   this.success = false;
    //   this.popUpForm.reset();
    //   document.querySelector('.modalHogar').classList.add('modalpop');
    //   document.querySelector('.modalHogar').classList.add('show');
    //   document.querySelector('.backdrop').classList.add('show');
    //   document.querySelector('.backdrop').classList.add('modal-backdrop');
    //   document.querySelector('.backdrop').classList.add('fade');

    // });

    // idle.onIdleStart.subscribe(() => {
    //   this.idleState = 'You\'ve gone idle!'


    // });

    // idle.onTimeoutWarning.subscribe((countdown) => {
    //   this.idleState = 'You will time out in ' + countdown + ' seconds!'

    // });
    // this.reset();


  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  // onCloseModal() {
  //   this.idle.watch();
  //   this.idleState = 'Started.';
  //   this.timedOut = false;
  //   this.error = false;
  //   this.success = false;
  //   this.popUpForm.reset();
  //   document.querySelector('.modalHogar').classList.remove('modalpop');
  //   document.querySelector('.modalHogar').classList.remove('show');
  //   document.querySelector('.backdrop').classList.remove('show');
  //   document.querySelector('.backdrop').classList.remove('modal-backdrop');
  //   document.querySelector('.backdrop').classList.remove('fade');
  // }

  // initRegisterForm() {
  //   this.popUpForm = this.fb.group({
  //     client_name: [null, [Validators.required]],
  //     client_lastname: [null, [Validators.required]],
  //     client_identityNumber: [null, [Validators.required, RutValidator]],
  //     client_phoneNumber: [null, [Validators.required]],
  //     client_email: [null, [Validators.required, Validators.email]],
  //     client_region: [null, [Validators.required]],
  //     client_commune: [null, [Validators.required]],
  //     client_address: [null, [Validators.required]],
  //     client_addressNumber: [null, [Validators.required]],
  //   })
  // }

  // isInvalidControl(control: string) {
  //   return this.formService.isInvalidControl(this.popUpForm, control);
  // }


  // send() {
  //   if (this.popUpForm.valid) {
  //     this.sendForm(this.popUpForm.value);
  //   } else {
  //     this.error = true;
  //   }
  // }

  // sendForm(body) {
  //   this.popupService.popUpForm(body).subscribe(
  //     (res) => {

  //     }
  //   );
  //   this.error = false;
  //   this.success = true;
  //   this.popUpForm.reset();

  // }

  ngOnInit(): void {
    // this.initRegisterForm();
  }
}
