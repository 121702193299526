<app-banner [url]="'/hogar/InternetHogar'"></app-banner>

<div class="container mt-3 ">
    <p>
        <b>
            Términos y condiciones generales
        </b>
    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li>
                <p>
                    Este plan es exclusivamente para clientes finales residenciales.
                </p>
            </li>
            <li>
                <p>
                    Esta estrictamente prohibida su explotación comercial o su reventa o redistribución a terceros.
                </p>
            </li>
            <li>
                <p>
                    Conoce los indicadores de velocidad de navegación Hogar
                </p>
            </li>
            <li>
                <p>
                    Servicio sujeto a factibilidad técnica y comercial. Revisar factibilidad técnica de servicios fijos
                </p>
            </li>
            <li>
                <p>
                    Tarifas y Condiciones válidas sólo para nuevas contrataciones.
                </p>
            </li>
            <li>
                <p>
                    Para contrataciones a partir del 17 de Febrero de 2020 aplican las siguientes condiciones
                    adicionales:
                </p>
            </li>
            <li>
                <p>
                    Conforme a las medidas de Gestión de tráfico y Administración de la red, a los usuarios del planes
                    fibra óptica que hagan uso indebido (reventa) de estos planes se les podría reducir la velocidad
                    máxima de navegación de bajada, en horario y/o lugares de congestión, (más información en
                    <a href="www.movistar.cl/neutralidad-en-la-red/" target="_blank">
                        www.movistar.cl/neutralidad-en-la-red/
                    </a>
                    ). Sin perjuicio del derecho de Telefónica Chile de dar término al contrato por incumplimiento de
                    las obligaciones del Cliente.
                </p>
            </li>
            <li>
                <p>
                    La velocidad máxima de bajada y subida dependerá de la factibilidad técnica de cada cliente.
                    Movistar siempre entregará al cliente la velocidad de bajada y subida máxima que la factibilidad
                    técnica permita, dentro del plan correspondiente. Si requiere el detalle de las factibilidades
                    técnicas por plan, puede revisarlo en las condiciones comerciales.
                </p>
            </li>
            <li>
                <p>
                    Planes de Internet Hogar no incluyen equipo extensor WiFi Extender. Valor por arriendo de $2.990
                    mensual. Cargo de instalación equipo WiFi Extender, posterior a la instalación del servicio de
                    Internet Hogar, de $20.000.
                </p>
            </li>
            <li>
                <p>
                    Aplicación Movistar Smart WiFi disponible solo para los siguientes routers: En Fibra óptica HGU
                    Askey RTF3505VWW, HGU Askey RTF3507VWHPNA, HGU Mitrastar GPT2541GNAC. En Cobre XDSL Mitrastar
                    DSL2401HNT1C, XDSL Askey RTV9015VWW, XDSL Mitrastar DSL2401HNA-T1CC, XDSL Mitrastar DSL-2401HN2-E1C.
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/RED_servicio_BandaAnchaFijo.pdf"
                        target="_blank">
                        Ver las medidas de gestión de tráfico y administración de la red.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/politica_descuentos.pdf"
                        target="_blank">
                        Ver los procedimientos de descuentos e indemnización.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/terminos-regulaciones/condiciones-comerciales-y-contractuales-hogar/a"
                        target="_blank">
                        Ver todas las condiciones contractuales y comerciales.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="http://medicionesenlaces.movistar.cl/baf/?_ga=2.34685281.130507210.1494342865-1824513391.1494339959"
                        target="_blank">
                        Calidad y Disponibilidad de los Enlaces
                    </a>
                </p>
            </li>
            <li>
                <p>
                    Fibra Simétrica 600 Megas: Promociones exclusivas contratando vía web, válida entre el 01 al 31 de
                    agosto de 2024, consistente en un 26% de descuento por 12 meses en el valor total del plan.


                </p>
            </li>
            <li>
                <p>
                    Fibra Simética 800 Megas: Promociones exclusivas contratando vía web, válida entre el 01 al 31 de
                    agosto de 2024, consistente en un 35% de descuento por 12 meses en el valor total del plan.


                </p>
            </li>
            <li>
                <p>
                    Fibra Simétrica Gamer 940 Megas: Promociones exclusivas contratando vía web, válida entre el 01 al
                    31 de agosto de 2024, consistente en un 43% de descuento por 12 meses en el valor total del plan.


                </p>
            </li>
            <li>
                <p>
                    A los clientes que contraten Planes que incluyan servicio internet Fibra Simétrica 800 Megas o Fibra
                    Simétrica Gamer 940 Megas, se les podrá entregar, sujeto a evaluación técnica al momento de la
                    instalación un extensor WiFi Extender sin costo mientras el cliente mantenga el plan indicado. En
                    caso de cambiar de Plan, el costo del extensor WiFI será de $2.990 mensuales.

                </p>
            </li>
            <li>
                <p>
                    Fibra Simétrica Turbo 2.000 Megas: Preventa del servicio válida entre el 01 y el 31 de Enero de
                    2023, 29% de descuento por 6 meses en el valor total del plan. Venta exclusiva contratando en
                    movistar.cl. Sujeto a factibilidad comercial, técnica y disponibilidad de equipos.
                </p>
            </li>
            <li>
                <p>
                    Todos los planes Fibra tienen incluido el beneficio de la aplicación Movistar TV App. Free.
                </p>
            </li>
        </ul>
    </div>
    <p>
        <b>
            Términos y Condiciones Planes Dúo TV y Telefonía
        </b>
    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li>
                <p>
                    Planes exclusivos para clientes finales residenciales.
                </p>
            </li>
            <li>
                <p>
                    Esta estrictamente prohibida su explotación comercial o su reventa o redistribución a terceros.
                </p>
            </li>
            <li>
                <p>
                    Tarifas y Condiciones válidas solo para nuevas contrataciones.
                </p>
            </li>
            <li>
                <p>
                    Sujeto a factibilidad técnica y comercial. Revisar factibilidad técnica de servicios fijos.
                </p>
            </li>
            <li>
                <p>
                    Para contrataciones a partir del 17 de agosto de 2020 aplican las siguientes condiciones
                    adicionales:
                </p>
            </li>
            <li>
                <p>
                    Conforme a las medidas de Gestión de tráfico y Administración de la red, a los usuarios del planes
                    fibra óptica que hagan uso indebido (reventa) de estos planes se les podría reducir la velocidad
                    máxima de navegación de bajada, en horario y/o lugares de congestión, (más información en
                    <a href="www.movistar.cl/neutralidad-en-la-red/" target="_blank">
                        www.movistar.cl/neutralidad-en-la-red/
                    </a>
                    ). Sin perjuicio del derecho de Telefónica Chile de dar término al contrato por incumplimiento de
                    las obligaciones del Cliente.
                </p>
            </li>
            <li>
                <p>
                    La velocidad máxima de bajada y subida dependerá de la factibilidad técnica de cada cliente.
                    Movistar siempre entregará al cliente la velocidad de bajada y subida máxima que la factibilidad
                    técnica permita, dentro del plan correspondiente. Si requiere el detalle de las factibilidades
                    técnicas por plan, puede revisarlo en las condiciones comerciales.
                </p>
            </li>
            <li>
                <p>
                    Planes de Internet Hogar no incluyen equipo extensor WiFi Extender. Valor por arriendo de $2.990
                    mensual. Cargo de instalación equipo WiFi Extender, posterior a la instalación del servicio de
                    Internet Hogar, de $20.000.
                </p>
            </li>
            <li>
                <p>
                    Aplicación Movistar Smart WiFi disponible solo para los siguientes routers: En Fibra óptica HGU
                    Askey RTF3505VWW, HGU Askey RTF3507VWHPNA, HGU Mitrastar GPT2541GNAC. En Cobre XDSL Mitrastar
                    DSL2401HNT1C, XDSL Askey RTV9015VWW, XDSL Mitrastar DSL2401HNA-T1CC, XDSL Mitrastar DSL-2401HN2-E1C.
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/RED_servicio_BandaAnchaFijo.pdf"
                        target="_blank">
                        Ver las medidas de gestión de tráfico y administración de la red.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/politica_descuentos.pdf"
                        target="_blank">
                        Ver los procedimientos de descuentos e indemnización.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/terminos-regulaciones/condiciones-comerciales-y-contractuales-hogar/a"
                        target="_blank">
                        Ver todas las condiciones contractuales y comerciales.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="http://medicionesenlaces.movistar.cl/baf/?_ga=2.34685281.130507210.1494342865-1824513391.1494339959"
                        target="_blank">
                        Calidad y Disponibilidad de los Enlaces
                    </a>
                </p>
            </li>
            <li>
                <p>
                    El precio del decodificador para servicio de Televisión Interactiva IPTV tendrá un descuento de
                    $3.990 mensuales, durante 60 meses, mientras el cliente mantenga contratado el servicio de
                    Televisión Movistar. Válido para clientes que contraten entre el 01 al 31 de agosto de 2024.
                </p>
            </li>
            <li>
                <p>
                    Para IPTV, el decodificador principal permite captar las señales de los canales nacionales de
                    televisión de libre recepción.

                </p>
            </li>
            <li>
                <p>

                    El servicio de televisión tiene asociado un costo de equipamiento de TV, el cual aplicará en caso
                    que decidas poner término al servicio de televisión, antes de 18 meses de haberlo contratado.
                    Quedarás liberado del pago del costo equipamiento de TV, si haces devolución a TCH del mismo, en
                    perfectas condiciones, considerando el desgaste por su uso normal. Revisa más detalles en las
                    Condiciones Contractuales y Comerciales de Televisión.

                </p>
            </li>
            <li>
                <p>
                    Servicio de Movistar TV HD sujeto a factibilidad técnica y comercial.
                </p>
            </li>
            <li>
                <p>
                    Para visualizar las señales HD incluidas en estos planes, debes contar con un televisor compatible
                    con tecnología HD (Full HD o HD Ready) y con un decodificador HD
                </p>
            </li>
            <li>
                <p>
                    Por razones técnicas la contratación del servicio IPTV sin Internet Hogar Movistar no permite la
                    visualización de las aplicaciones interactivas de IPTV y/o ciertos contenidos de TV On Demand
                    (Netflix, Disney+, Star+, Paramount+, HBO, FOX, otros).
                </p>
            </li>
            <li>
                <p>
                    Para Movistar TV HD el plan incluye la instalación de un decodificador. En el evento que el cliente
                    solicite 1 o más decodificadores adicionales o, asimismo, para domicilios con problemas de cobertura
                    de Internet Hogar, es decir, que el decodificador instalado en modalidad wifi, no alcance los -65
                    dBm de potencia, se requerirá de forma adicional la instalación de un  repetidor de señal de acuerdo
                    a la factibilidad técnica del domicilio cuya renta mensual es de $1.990 adicionales al precio del
                    plan, ello con el objetivo de asegurar la estabilidad y funcionamiento del servicio. En los casos en
                    que se requieran 4 y/o 5 decodificadores inclusive se asignará un repetidor en todos los casos con
                    el objeto de garantizar el correcto funcionamiento del servicio. El costo de los decodificadores
                    adicionales es de $3.990 cada uno.
                </p>
            </li>
            <li>
                <p>
                    El costo de instalación de los planes Singles del servicio de Televisión es de $34.990.
                </p>
            </li>
            <li>
                <p>
                    Dúo Fibra TV Disney Premium MAX 600 Megas : Promociones exclusivas contratando vía web, válida entre
                    el 01 al 31 de agosto de 2024, consistente en un 33% de descuento por 6 meses en el valor total del
                    plan.
                </p>
            </li>
            <li>
                <p>
                    Dúo Fibra TV Disney Premium MAX 800 Megas: Promociones exclusivas contratando vía web, válida entre
                    el 01 al 31 de agosto de 2024, consistente en un 36% de descuento por 6 meses en el valor total del
                    plan.

                </p>
            </li>
            <li>
                <p>
                    Dúo Fibra TV Disney Premium MAX Gamer 940 Megas: Promociones exclusivas contratando vía web, válida
                    entre el 01 al 31 de agosto de 2024, consistente en un 34% de descuento por 6 meses en el valor
                    total del plan.
                </p>
            </li>

            <li>
                <p>
                    "Todas las contrataciones a partir del 13 de febrero 2023 incluyen como beneficio el servicio de
                    Streaming Paramount+. Adicionalmente todas las nuevas contrataciones a partir del 27 de febrero 2024
                    incluyen como beneficio el servicio de Streaming Max y desde el 26 de junio de 2024 incluyen además
                    Disney+Premium.
                    Por cambios en la plataforma del proveedor del servicio, aquellos clientes que contrataron plan de
                    Televisión que incluía Star+ o Combo+, con anterioridad al 26 de junio de 2024, serán migrados, sin
                    costo adicional a la plataforma Disney+ Premium.
                    Los beneficios de streaming Disney+ Premium, Paramount+ y MAX incluidos como beneficio en
                    determinados planes está sujeto a acuerdos con los proveedores de estos servicios, por lo tanto,
                    podrá ser eliminado o reemplazado en cualquier momento de la vigencia del plan, sin responsabilidad
                    para TCH. Condiciones comerciales y contractuales en movistar.cl. Sujeto a factibilidad técnica y
                    comercial."
                </p>
            </li>
            <li>
                <p>Los planes de televisión contratados desde el 13 de febrero de 2023, tendrán como beneficio adicional
                    las señales de televisión Comedy Central, MTV, MTV Live, MTV 00s, Nick, Nick Jr., Teen Nick,
                    Paramount, que el cliente podrá visualizar hasta el 31 de Enero de 2024, posterior a esta fecha
                    Movistar podría eliminarlos de su grilla de canales.
                </p>
            </li>


            <li>
                <p>
                    A los clientes que contraten Planes que incluyan servicio internet Fibra Simétrica 800 Megas o Fibra
                    Simétrica Gamer 940 Megas, se les podrá entregar, sujeto a evaluación técnica al momento de la
                    instalación un extensor WiFi Extender sin costo mientras el cliente mantenga el plan indicado. En
                    caso de cambiar de Plan, el costo del extensor WiFI será de $2.990 mensuales.

                </p>
            </li>
            <li>
                <p>
                    Todos los planes Hogar con Televisión incluyen Movistar TV Multipantalla. Los planes Hogar sin
                    Televisión Movistar incluyen Movistar TV Free. Este beneficio puede ser eliminado y/o reemplazado a
                    discreción de TCH.
                </p>
            </li>

            <li>
                <p>
                    La contratación de los Canales Premium es por un período de 30 días. El primer mes se facturará
                    proporcionalmente desde la fecha de activación hasta la fecha de término del ciclo de facturación
                    del cliente. El valor de los canales Premiun puede sufrir modificaciones.
                </p>
            </li>
            <li>
                <p>
                    El cobro de los canales Premium es por mes completo. Por lo anterior, en caso de solicitar su baja
                    podrá continuar visualizando el canal hasta el término del período.  Si el Cliente quiere dar
                    término al canal, recomendamos solicitarlo antes de que se renueve el período, según fecha de
                    activación.
                </p>
            </li>
            <li>
                <p>
                    Se podrá solicitar la baja del Canal Premium en cualquier momento de nuestros canales de atención,
                    nuestra recomendación es hacerlo a través de los canales virtuales,  a través del 600 600 3000 o en
                    Sucursal Virtual de movistar.cl o App Mi Movistar
                </p>
            </li>
            <li>
                <p>
                     Las promociones publicadas asociadas a Canales Premium una vez finalizadas involucran el cobro
                    normal del respectivo plan, siendo responsabilidad del Cliente la descontratación del mismo en caso
                    de no desear continuar con el servicio.
                </p>
            </li>
            <li>
                <p>
                    Trío Fibra Disney Premium MAX 600 Megas: Promociones exclusivas contratando vía web, válida entre el
                    01 al 31 de agosto de 2024, consistente en un 28% de descuento por 6 meses en el valor total del
                    plan.
                </p>
            </li>
            <li>
                <p>
                    Trío Fibra Disney Premium MAX 800 Megas: Promociones exclusivas contratando vía web, válida entre el
                    01 al 31 de agosto de 2024, consistente en un 29% de descuento por 6 meses en el valor total del
                    plan.
                </p>
            </li>
            <li>
                <p>
                    Trío Fibra Disney Premium MAX Gamer 940 Megas: Promociones exclusivas contratando vía web, válida
                    entre el 01 al 31 de agosto de 2024, consistente en un 29% de descuento por 6 meses en el valor
                    total del plan.
                </p>
            </li>
            <li>
                <p>
                    Trío Fibra Disney Premium MAX Gamer 940 Megas: Promociones exclusivas contratando vía web, válida
                    entre el 26 al 30 de junio de 2024, consistente en un 29% de descuento por 6 meses en el valor total
                    del plan.
                </p>
            </li>

            <li>
                <p>
                    A los clientes que contraten Planes que incluyan servicio internet Fibra Giga Simétrica, se les
                    podrá entregar, sujeto a evaluación técnica al momento de la instalación un extensor WiFi Extender
                    sin costo mientras el cliente mantenga el plan indicado. En caso de cambiar de Plan, el costo del
                    extensor WiFI será de $2.990 mensuales.
                </p>
            </li>
            <li>
                <p>
                    Todos los planes Hogar con Televisión incluyen Movistar TV Multipantalla. Los planes Hogar sin
                    Televisión Movistar incluyen Movistar TV Free. Este beneficio puede ser eliminado y/o reemplazado a
                    discreción de TCH.
                </p>
            </li>
            <li>
                <p>
                    "Todas las contrataciones a partir del 13 de febrero 2023 incluyen como beneficio el servicio de
                    Streaming Paramount+. Adicionalmente todas las nuevas contrataciones a partir del 27 de febrero 2024
                    incluyen como beneficio el servicio de Streaming Max y desde el 26 de junio de 2024 incluyen además
                    Disney+Premium.
                    Por cambios en la plataforma del proveedor del servicio, aquellos clientes que contrataron plan de
                    Televisión que incluía Star+ o Combo+, con anterioridad al 26 de junio de 2024, serán migrados, sin
                    costo adicional a la plataforma Disney+ Premium.
                    Los beneficios de streaming Disney+ Premium, Paramount+ y MAX incluidos como beneficio en
                    determinados planes está sujeto a acuerdos con los proveedores de estos servicios, por lo tanto,
                    podrá ser eliminado o reemplazado en cualquier momento de la vigencia del plan, sin responsabilidad
                    para TCH. Condiciones comerciales y contractuales en movistar.cl. Sujeto a factibilidad técnica y
                    comercial."
                </p>
            </li>

            <li>
                <p>
                    El cobro de los canales Premium es por mes completo. Por lo anterior, en caso de solicitar su baja
                    podrá continuar visualizando el canal hasta el término del período.  Si el Cliente quiere dar
                    término al canal, recomendamos solicitarlo antes de que se renueve el período, según fecha de
                    activación.
                </p>
            </li>
            <li>
                <p>
                    Se podrá solicitar la baja del Canal Premium en cualquier momento de nuestros canales de atención,
                    nuestra recomendación es hacerlo a través de los canales virtuales,  a través del 600 600 3000 o en
                    Sucursal Virtual de movistar.cl o App Mi Movistar
                </p>
            </li>
            <li>
                <p>
                     Las promociones publicadas asociadas a Canales Premium una vez finalizadas involucran el cobro
                    normal del respectivo plan, siendo responsabilidad del Cliente la descontratación del mismo en caso
                    de no desear continuar con el servicio.
                </p>
            </li>


            <li>
                <p>
                    <a href="https://ww2.movistar.cl/terminos-regulaciones/condiciones-comerciales-y-contractuales-hogar/a"
                        target="_blank">
                        Ver todas las condiciones contractuales y comerciales.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    Condiciones comerciales de los minutos a móviles con Plan Minutos Locales Ilimitados
                    El plan de Minutos Locales Ilimitado excluye, entre otras, comunicaciones a redes de compañías que
                    presten servicios en zonas rurales, compañías satelitales y aquellas que tengan una tarifa de cargo
                    de acceso superior a Telefónica Chile. Incluye 300 minutos a móviles, de cualquier compañía.
                </p>
            </li>
            <li>
                <p>
                    Los minutos a móviles son mensuales, no son acumulables de un mes a otro y deben generarse desde la
                    línea fija hacia cualquier compañía móvil y en cualquier horario.
                    Una vez consumidos esos minutos, las llamadas serán cobradas al valor vigente del minuto a teléfonos
                    móviles.

                </p>
            </li>
            <li>
                <p>
                    Para usar los minutos a móviles el cliente deberá solicitar desbloqueo de llamadas a móviles.
                </p>
            </li>
            <li>
                <p>
                    Para consultas de saldo se puede llamar al 600 600 4300.
                </p>
            </li>
            <li>
                <p>
                    Servicio sujeto a factibilidad técnica y comercial.
                </p>
            </li>
        </ul>
    </div>
    <p>
        <b>
            Condiciones técnicas mínimas para la instalación del servicio de Internet Hogar
        </b>
    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li class="noStyleList">
                <b>
                    Windows:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Procesador: 1GHz 32-bit (x86) o 64-bit (x64)
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Sistema Operativo: Windows 2000 Sp4, Windows XP SP2, Vista (32-64-bit), Windows 7/8 (32-64-bit) o
                    Windows 10 (32-64 bit)
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Mac
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Procesador: PowerPC (G3, G4, G5, iMac)
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Sistema Operativo: Mac OS 9, Mac High Sierra, Mac Sierra, Yosemite, El Capitan

                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Para Windows y/o Mac:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Espacio Libre en Disco Duro: Mínimo 2 GB
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Memoria RAM: Mínimo 4 GB
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Tarjeta Ethernet: GE (1.000 Mbps)
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - WiFi: IEEE 802.11 b/g/n
                </p>
            </li>
        </ul>
    </div>
    <p>
        <b>
            Condiciones técnicas para la instalación del servicio de Internet Hogar desde 500 a 940Mbps
        </b>
    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li class="noStyleList">
                <b>
                    Windows:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Procesador: I5 64-bit (x64) equivalente o superior
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Sistema Operativo: Windows 7/10 (64-bit)
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Mac:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Procesador: I5 64-bit (x64) equivalente o superior
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Sistema Operativo: Mac OS 10"
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Para Windows y/o Mac:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Espacio Libre en Disco Duro: Mínimo 120 GB SSD
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Memoria RAM: Mínimo 8 GB
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Tarjeta Ethernet: GE (1.000 Mbps full duplex)
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Navegador: FireFox 72.0.2 (64-bit)
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Para la contratación de Fibra Simétrica Óptica desde 400 Megas y en especial para el Plan FO 940
                    Megas se requiere:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Contar con un puerto Gigabit Ethernet.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Contar con una Memoria RAM mínimo de 8GB.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Contar con procesador de 4 o más núcleos I5 equivalente o superior.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Contar con las últimas versiones de navegadores.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Las pruebas siempre deben ser efectuadas por cable Ethernet Cat5e o superior, sin otros
                    dispositivos conectados a la red, ni por Ethernet ni por WiFi.
                </p>
            </li>
        </ul>
    </div>


    <!-- <span>
        Para Movistar TV Pro el plan incluye la instalación de un decodificador. En el evento que el cliente solicite 1
        o más decodificadores adicionales o, asimismo, para domicilios con problemas de cobertura de Internet Hogar, es
        decir, que el decodificador
    </span> -->
</div>


<app-footer-hogar></app-footer-hogar>