import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private loaderForm: boolean = false;
  private loaderForm$: BehaviorSubject<boolean> = new BehaviorSubject(this.loaderForm);

  constructor() { }

  get getLoaderForm$(): Observable<boolean>{
    return this.loaderForm$.asObservable()
  }


  openLoader(){
    this.loaderForm$.next(true);
  }

  closeLoader(){
    setTimeout(() => {
      this.loaderForm$.next(false);
    }, 500);
  }

  addFormGruop(form: FormGroup, formGroupName: string){
    form.addControl(formGroupName, new FormGroup({}));
  }

  addControl(form: FormGroup, controlName: string, validators: any[], value:any = null){
    form.addControl(controlName, new FormControl(value, validators));
  }

  clearControl(form: FormGroup, control: string) {
    form.get(control).setValue(null);
  }

  clearForm(form: FormGroup) {
    Object.values(form.controls).forEach(control => {

      if (!(control instanceof FormGroup)) {
        control.setValue(null);
        control.markAsUntouched();
      }else{
        this.clearForm(control);
      }

    })
  }

  clearAllForm(form: FormGroup) {
    Object.values(form.controls).forEach(control => {

      if (!(control instanceof FormGroup)) {
        control.setValue(null);
        control.markAsUntouched();
        control.clearAsyncValidators();
        control.clearValidators();
        control.updateValueAndValidity();
      }

    })
  }


  validForm(form: FormGroup): boolean {
    if (form.invalid) {
      Object.values(form.controls).forEach(control => {
        if (control instanceof FormGroup) {
          this.validForm(control);
        }
        control.markAsTouched();
      })
    }
    return form.valid;
  }

  isInvalidControl(form: FormGroup, control: string): boolean {
    return form.get(control).invalid && form.get(control).touched;
  }

  isValidControl(form: FormGroup,control: string): boolean {
    return form.get(control).valid && form.get(control).touched;
  }

  onChangeControl(form: FormGroup,control: string): Observable<any> {
    return form.get(control).valueChanges;
  }

  getControl(form :FormGroup, control: string): FormControl{
    return form.get(control) as FormControl;
  }

  getFormgroup(form :FormGroup, formgroup: string): FormGroup{
    return form.get(formgroup) as FormGroup;
  }

  

}
