import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header-pre-plan',
  templateUrl: './header-pre-plan.component.html',
  styleUrls: ['./header-pre-plan.component.scss']
})
export class HeaderPrePlanComponent implements OnInit {

  public itemActive: string = 'itemMovil1';
  public itemMovil;
  public openSideBar: boolean;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void { 
    window.scroll(0, 0)
    this.route.url.subscribe((params: any) => {
    })
    this.route.firstChild.params.subscribe((params: any) => {
    });
  }


  closeNest(){
    const dropdown = document.getElementById('drop');
    dropdown?.classList.remove('show');
  }



}
