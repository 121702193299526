<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-dark shadow bgMovistar pb-md-0 sticky-top">
  <!-- Container wrapper -->
  <div class="container d-flex justify-content-between align-items-center py-2">

    <!-- Navbar brand -->
    <a class="navbar-brand fw-light text-dark rounded-5 d-none d-md-block">
      <img src="../../../../assets/img/logo-movistar-white.svg" alt="Movistar_logo" width="150" height="30">
    </a>

    <a class="navbar-brand fw-light text-dark rounded-5 d-block d-md-none logo-movil">
      <img src="../../../../assets/img/LogoMovistarMobile.svg" alt="Movistar_logo" width="100%" height="30">
    </a>



    <!-- Collapsible wrapper -->
    <div class="containerNav d-none d-md-block" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-lg-0 d-flex flex-row justify-content-center gap-5">
        <!-- Link -->

        <!-- Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-mdb-toggle="dropdown"
            aria-expanded="false">
            Hogar
          </a>
          <!-- Dropdown menu -->
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a class="dropdown-item" [routerLink]="['/hogar', 'InternetHogar']">Internet Hogar</a>
            </li>
            <li>
              <a class="dropdown-item">
                Packs Dúos
              </a>
              <ul class="dropdown-menu dropdown-submenu">
                <li>
                  <a class="dropdown-item" [routerLink]="['PacksDuosCombo']">Dúo TV Combo+</a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['PacksDuosStar']">Dúo Star+</a>
                </li>
              </ul>
            </li>
            <li>
              <a class="dropdown-item">Pack Tríos</a>
              <ul class="dropdown-menu dropdown-submenu">
                <li>
                  <a class="dropdown-item" [routerLink]="['PacksTriosCombo']">Trío Combo +</a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['PacksTriosStar']">Trío Star +</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-mdb-toggle="dropdown"
            aria-expanded="false">
            Móvil
          </a>
          <!-- Dropdown menu -->
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" [routerLink]="['/movil/PlanesIndividuales']">Planes Individuales</a></li>
            <li>
              <a class="dropdown-item" [routerLink]="['/movil/Planes2x']">Planes 2X</a>
              
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/plan-para-todos/PlanesParaTodos']">Plan para todos</a>
        </li>
      </ul>
    </div>
    <div class="w9 d-none d-md-block"></div>


    <div class="containerNav d-block d-md-none" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-lg-0 d-flex flex-row justify-content-center gap-4">
        <!-- Link -->

        <!-- Dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-bs-auto-close="outside" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Hogar
          </a>
          <ul class="dropdown-menu" id="drop">
            <li><a class="dropdown-item" href="#">Internet Hogar</a></li>
            <li>
              <a class="dropdown-item dropdown-toggle" role="button"  data-bs-toggle="dropdown">Packs Dúos</a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="['/hogar','PacksDuosCombo']" (click)="closeNest()">Dúo TV Combo+</a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/hogar','PacksDuosStar']" (click)="closeNest()">Dúo Star+</a>
                </li>
              </ul>
            </li>
            <li>
              <a class="dropdown-item dropdown-toggle" role="button" data-bs-toggle="dropdown">Packs Tríos</a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" [routerLink]="['/hogar','PacksTriosCombo']" (click)="closeNest()">Trío Combo +</a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/hogar','PacksTriosStar']" (click)="closeNest()">Trío Star +</a>
                </li>
              </ul>
            </li>
          </ul>

        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Móvil
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" [routerLink]="['/movil/PlanesIndividuales']">Planes Individuales</a></li>
            <li>
              <a class="dropdown-item" [routerLink]="['/movil/Planes2x']">Planes 2X</a>
              
            </li>
          </ul>

        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" [routerLink]="['/plan-para-todos/PlanesParaTodos']" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Plan para todos
          </a>
        </li>
      </ul>
    </div>
    

  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->