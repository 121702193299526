<app-banner [url]="'/movil/PlanesIndividuales/'"></app-banner>
<div class="container mt-3 ">
    <p>
        <b>
            Terminos y condiciones Movil:
        </b>
    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li>
                <p>
                    Conoce los
                    <a href="https://ww2.movistar.cl/movil/indicadores-telefonia-movil/" target="_blank">
                        indicadores de velocidad de navegación móvil
                    </a>
                </p>
            </li>
            <li>
                <p>
                    Vigencia 1 al 31 de Enero de 2023.
                </p>
            </li>
            <li>
                <p>
                    Promoción exclusiva para nuevas contrataciones Monolíneas, válida para el Plan 5G libre Full con 14%
                    de descuento por 6 meses, desde mes 7 pagará valor normal del plan publicado.
                </p>
            </li>
            <li>
                <p>
                    Promoción exclusiva para nuevas contrataciones Monolíneas, válida para el Plan 5G Libre Pro y Ultra
                    con 20% de descuento por 6 meses, desde mes 7 pagará valor normal del plan publicado.
                </p>
            </li>
            <li>
                <p>
                    Promoción 2 x, exclusivo portabilidad y clientes vigentes para los planes libres 5G libre Full,
                    consiste en un descuento de 46% y para los planes libres 5G libre Pro y Ultra consiste en un
                    descuento de 50%, aplicado al plan por 6 meses, y bajo mismo rut, mientras se mantengan ambas líneas
                    contratadas. Desde mes 7 aplica precio normal publicado en Movistar.cl. Clientes vigentes requieren
                    modificar plan actual.
                </p>
            </li>
            <li>
                <p>
                    Precios informados podrán ser reajustados de acuerdo al IPC según se detalla en contratos de
                    servicios.
                </p>
            </li>
            <li>
                <p>
                    <b>
                        Plan 5G Libre Inicia:
                    </b>
                    Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los usuarios del plan 5G
                    Libre Inicia se le podría reducir la velocidad máxima de navegación de bajada hasta 128 kbps, en
                    horario y/o lugares de congestión, en el evento de que su consumo de datos haya sobrepasado los 100
                    GB.
                </p>
            </li>
            <li>
                <p>
                    <b>
                        Plan 5G Libre Full:
                    </b>
                    Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los usuarios del plan 5G
                    Libre Full se le podría reducir la velocidad máxima de navegación de bajada hasta 128 kbps, en
                    horario y/o lugares de congestión, en el evento de que su consumo de datos haya sobrepasado los 200
                    GB.
                </p>
            </li>
            <li>
                <p>
                    <b>
                        Plan 5G libre Pro:
                    </b>
                    Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los usuarios del Plan 5G
                    Libre Pro se le podría reducir la velocidad máxima de navegación de bajada hasta 2 Mbps, en horario
                    y/o lugares de congestión, en el evento de que su consumo de datos haya sobrepasado los 300 GB.
                </p>
            </li>
            <li>
                <p>
                    <b>
                        Plan 5G Libre Ultra:
                    </b>
                    Conforme a las medidas de Gestión de Tráfico y Administración de la Red, a los usuarios del Plan 5G
                    Libre Ultra se le podría reducir la velocidad máxima de navegación de bajada hasta 4 Mbps, en
                    horario y/o lugares de congestión, en el evento de que su consumo de datos haya sobrepasado los 450
                    GB.
                </p>
            </li>
            <li>
                <p>
                    Los minutos libres incluidos en el respectivo plan, aplican sólo para un máximo de 300 destinatarios
                    por mes. Tanto los minutos generados a destinatarios adicionales, como las comunicaciones a redes de
                    compañías cuyas tarifas de cargo de acceso sean superiores a las de Movistar, no se encuentran
                    incluidas en la renta del Plan contratado y tendrán el valor consignado en el anexo “Listado de
                    servicios y cobros”.
                </p>
            </li>
            <li>
                <p>
                    Los mensajes de texto que excedan la cantidad de SMS incluidos en el plan, tendrán el valor
                    consignado en anexo “Listado de servicios y cobros”.
                </p>
            </li>
            <li>
                <p>
                    Todos los planes incluyen Movistar Tv Free. Este beneficio puede ser eliminado y/o reemplazado a
                    discreción de TMCH.
                </p>
            </li>
            <li>
                <p>
                    Navegarás a una velocidad promedio (de bajada) de 7 Megabits por segundo (Mbps).
                </p>
            </li>
            <li>
                <p>
                    El Cliente deberá dar al servicio un uso conforme a la racionalidad del mismo, considerando el
                    segmento para el que fue diseñado.
                </p>
            </li>
            <li>
                <p>
                    Está prohibido el uso y explotación comercial o industrial del servicio incluido en el plan
                    respectivo y también la explotación, reventa y/o comercialización de tráfico, que se genere en
                    ocasión o como consecuencia del uso de los servicios incluidos en el plan respectivo, cualquiera sea
                    su origen y/o destino.
                </p>
            </li>
            <li>
                <p>
                    Los Gigabytes del servicio incluidos en el Planes Servicio Móvil y en el Plan sólo Datos, sólo
                    pueden ser utilizados por parte del cliente (suscriptor y/o usuario) en su teléfono móvil y nunca a
                    través de dispositivos como módems, punto hotspot, aparatos M2M, máquinas generadoras de trafico
                    automático y/o de uso fraudulento o a través de cualquier otro medio que permita el acceso y/o
                    compartir su uso a múltiples usuarios, excepto en el caso de que la conexión se comparta a través
                    del teléfono móvil.
                </p>
            </li>
            <li>
                <p>
                    Los equipos se conectarán en función de su tecnología a las distintas redes disponibles. (2G/3G/4G
                    LTE/5G), dependiendo del punto de cobertura por la cual transite el cliente y de las condiciones del
                    plan contratado.
                </p>
            </li>
            <li>
                <p>
                    Para conocer las zonas con cobertura
                    <a href="https://ww2.movistar.cl/cobertura-movil/">
                        revisa aquí
                    </a>
                </p>
            </li>
            <li>
                <p>
                    Movistar pondrá a disposición de todos sus clientes que cuenten con un equipo compatible con
                    tecnología 5G, la posibilidad de acceder a esta red en las zonas de cobertura, sin cargos
                    adicionales.
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Planes con equipo nuevo:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Valor sujeto a contrato de arriendo con opción de compra a 18 meses.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Valor del equipo varia en función del plan que selecciones.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Valor cuota equipo varía de acuerdo a cada modelo.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - El costo de despacho de los equipos es de $2.990.
                </p>
            </li>
            <li class="noStyleList">
                <b>
                    Planes sin equipo:
                </b>
            </li>
            <li class="noStyleList">
                <p>
                    - Sólo disponible para clientes con equipo
                </p>
            </li>
            <li>
                <b>
                    Todas las tarifas incluyen IVA.
                </b>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/RED_servicio_BandaAnchaFijo.pdf"
                        target="_blank">
                        Ver las medidas de gestión de tráfico y administración de la red.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/centro-de-ayuda/documentos/politica_descuentos.pdf"
                        target="_blank">
                        Ver los procedimientos de descuentos e indemnización.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="https://ww2.movistar.cl/terminos-regulaciones/condiciones-comerciales-y-contractuales-hogar/a"
                        target="_blank">
                        Ver todas las condiciones contractuales y comerciales.
                    </a>
                </p>
            </li>
            <li>
                <p>
                    <a href="http://medicionesenlaces.movistar.cl/baf/?_ga=2.34685281.130507210.1494342865-1824513391.1494339959"
                        target="_blank">
                        Calidad y Disponibilidad de los Enlaces
                    </a>
                </p>
            </li>
        </ul>
    </div>
    <p>
        <b>
            Planes para todos:
        </b>
    </p>
    <div class="col-12 d-flex justify-text-center">
        <ul>
            <li class="noStyleList">
                <p>
                    Plan exclusivo para nuevas contrataciones, Alta y portabilidad. En estos casos, los clientes deberán
                    adquirir el plan para Todos y se les entregará una nueva simcard, para el caso de Alta Nueva se les
                    asignará un nuevo número telefónico.
                </p>
            </li>
            <li>
                <p>
                    Podrá acceder al Plan para Todos cualquier persona natural, que cumpla con los requisitos señalados
                    en el punto anterior, sin requisitos ni documentación alguna y sin evaluación comercial.
                </p>
            </li>
            <li>
                <p>
                    El cliente podrá optar entre los siguientes planes:
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Plan para todos 4.990 por $4.990 incluye 40GB + 500 minutos + RRSS* libres por 30 días.
                </p>
            </li>
            <li class="noStyleList">
                <p>
                    - Plan para todos 7.990 por $7.990 incluye 70GB + 1000 minutos + RRSS* libres por 30 días.
                </p>
            </li>
            <li>
                <p>
                    *Redes sociales libres incluidas en el plan: WhatsApp, Facebook, Twitter, Facebook Messenger e
                    Instagram. Excluye el uso de llamadas de voz sobre internet, video llamadas, acceso a enlaces
                    externos y mapas y toda reproducción de música en calidad superior a la estándar o que adiciones
                    reproducción de video, los que serán descontados de los GB incluidos en el plan. El beneficio de
                    redes sociales puede ser eliminado total o parcialmente a discreción de Movistar.
                </p>
            </li>
            <li>
                <p>
                    Los planes no incluyen Mensajes de Texto SMS. Los beneficios incluidos en Plan para Todos son
                    válidos solo para su uso en Chile, no son para llamadas internacionales ni Roaming internacional.
                </p>
            </li>
            <li>
                <p>
                    El plan activado por el cliente tendrá una vigencia de 30 días y se renovará automáticamente al
                    vencimiento de este período, siempre que el Cliente cuente con saldo suficiente para cubrir el cargo
                    fijo del plan respectivo.
                </p>
            </li>
            <li>
                <p>
                    En caso de contar con el saldo requerido para la renovación del plan en la fecha correspondiente,
                    éste se activará cuando se haya completado la recarga necesaria, plazo desde el cual se contarán los
                    30 días de vigencia.
                </p>
            </li>
            <li>
                <p>
                    El monto requerido mensualmente para la activación del plan se podrá completar acumulando recargas
                    de menor monto o bien mediante una sola recarga. Dichas recargas pueden realizarse a través de la
                    App Mi Movistar, Página web y por cualquier otro medio autorizado.
                </p>
            </li>
            <li>
                <p>
                    Si el cliente consume los GB o minutos del plan antes de su renovación, no podrá continuar navegando
                    ni podrá realizar llamadas, sino que solo recibirlas. En estos casos el Cliente podrá adquirir
                    cualquiera de las bolsas que se encuentren disponibles.
                </p>
            </li>
            <li>
                <p>
                    El cliente recibirá SMS recordando que debe tener el saldo suficiente para poder activar el Plan.
                    Estos mensajes no tienen fines promocionales ni publicitarios.
                </p>
            </li>
        </ul>
    </div>
</div>
<app-footer-movil></app-footer-movil>