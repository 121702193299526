<app-banner [url]="'/hogar/InternetHogar'"></app-banner>
<div class="container mt-1 mb-4">

    <div class="row d-flex justify-content-center">
        <div class="col-12 text-center pt-2">
            <h2>Sucursales Movistar Disponibles</h2>
        </div>
        <div class=" container d-flex justify-content-center">
            <ul class="nav nav-pills pt-3 d-flex justify-content-center">
                <li class="nav-item ">
                    <div class="mt-1 me-1 mb-3">
                        <button class="active2 " [ngClass]="{'activado': nortActive}" routerLinkActive="active" (click)="changeCategory('11')">Zona Norte</button>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="mt-1 me-1 mb-3">
                        <button class="active2 " [ngClass]="{'activado': rmActive}"  routerLinkActive="active" (click)="changeCategory('10')">RM</button>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="mt-1 me-1  mb-3">
                        <button class="active2 " [ngClass]="{'activado': southActive}"  (click)="changeCategory('12')">Zona Sur</button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-12  text-center">
            <p class="fw-bold"> *Listado actualizado el 10 de Enero a las 13:00 hrs. </p>
            <br>
            <p>**R=Recambio; AC=Atención Comercial; A=Accesorios; D=Devolución de modems, repetidores y codificadores;
                ST=Servicio Técnico ingreso de equipos.</p>
        </div>
        <div class="table-responsive">
            <table class="table mb-0 text-center">
                <thead>
                    <tr>
                        <th class="bgAzulOscuro text-white text-capitalize">
                            <h6>Sucursal</h6>
                        </th>
                        <th class="bgAzulOscuro text-white text-capitalize">
                            <h6>
                                Direccion
                            </h6>
                        </th>
                        <th class="bgAzulOscuro text-white text-capitalize">
                            <h6>
                                Horarios*
                            </h6>
                        </th>
                        <th class="bgAzulOscuro text-white text-capitalize">
                            <h6>
                                Servicio**
                            </h6>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sucursal of sucursales">
                        <td>
                            {{sucursal.sucursal}}
                        </td>
                        <td>
                            {{sucursal.direccion}}
                        </td>
                        <td>
                            {{sucursal.horarios}}
                        </td>
                        <td>
                            {{sucursal.servicio}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-footer-hogar></app-footer-hogar>